import { Tooltip } from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import Compressor from "compressorjs";
import dayjs, { Dayjs } from "dayjs";
import countries from "i18n-iso-countries";
import {
  ChangeEvent,
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from "react";
import ReactFlagsSelect from "react-flags-select";
import { allTimezones, useTimezoneSelect } from "react-timezone-select";
import NotificationsApi from "../../../../api/notifications";
import UsersApi from "../../../../api/usersApi";
import EditIcon from "../../../../assets/Profile/cloud-plus.svg";
import CircleAvatar from "../../../../components/CircleAvatar/CircleAvatar";
import CityPicker, {
  PlaceType,
} from "../../../../components/CitySelect/CitySelect";
import CropAvatarModal from "../../../../components/CropAvatarModal/CropAvatarModal";
import Input from "../../../../components/Input/Input";
import MultipleSelect from "../../../../components/MultipleSelect/MultipleSelect";
import StandartButton from "../../../../components/StandartButton/StandartButton";
import { popularLanguages } from "../../../../constans/languagesList";
import { ContextProvider } from "../../../../contextProvider";
import strings from "../../../../localization";
import localizationJson from "../../../../localization.json";
import { TypeUpdatedUserInfo, TypeUserData } from "../../../../types/TypeUsers";
import s from "./EditClientProfile.module.css";

const labelStyle = "original";
const timezones = {
  ...allTimezones,
  "Europe/Berlin": "Frankfurt",
};

type EditProfileProps = {
  userUpdateData: TypeUpdatedUserInfo;
  profileUserData: TypeUserData | undefined | null;
  setUserUpdateData: Dispatch<SetStateAction<TypeUpdatedUserInfo>>;
  updateData: () => void;
  calendarIsConnected: boolean;
  setAvatarFile: Dispatch<SetStateAction<File | null>>;
  avatarFile: File | null;
};

const EditClientProfile = ({
  userUpdateData,
  profileUserData,
  setUserUpdateData,
  updateData,
  calendarIsConnected,
  setAvatarFile,
  avatarFile,
}: EditProfileProps) => {
  const token = localStorage.getItem("token");
  const { userData, setSelectLang, setUserData, selectLang } =
    useContext(ContextProvider);
  const { options } = useTimezoneSelect({
    labelStyle,
    timezones,
  });
  const [availableSiteLanguages, setAvailableSiteLanguages] = useState<
    { lable: string; value: string }[] | null
  >(null);
  const [dateOfBirth, setDateOfBirth] = useState<Date | null>(null);
  const [selectedCity, setSelectedCity] = useState<PlaceType | null>(null);
  const [selectedCountry, setSelectedCountry] = useState("");
  const [langages, setLangages] = useState<string | string[]>([]);
  const [settingsAvatarModal, setSettingsAvatarModal] = useState(false);

  const onChangeSpecialistDataText = (
    value: any,
    objKey: keyof TypeUpdatedUserInfo,
    maxLength?: number
  ) => {
    let trimmedValue = value.trim();
    if (maxLength && trimmedValue.length > maxLength) {
      trimmedValue = trimmedValue.substring(0, maxLength);
    }

    setUserUpdateData((prev) => ({
      ...prev,
      [objKey]: trimmedValue,
    }));
  };
  const languagesList = popularLanguages.map((item) => ({
    value: item,
    lable: item,
  }));

  const languageNames: { [key: string]: string } = {
    en: "English",
    uk: "Українська",
  };

  useEffect(() => {
    if (
      selectedCity?.terms[0].value &&
      selectedCity?.terms[selectedCity?.terms.length - 1].value
    )
      setUserUpdateData((prev) => ({
        ...prev,
        location:
          selectedCity?.terms[0].value +
          ", " +
          selectedCity?.terms[selectedCity?.terms.length - 1].value,
      }));
  }, [selectedCity]);

  useEffect(() => {
    const languageArray: {
      lable: string;
      value: string;
    }[] = Object.keys(localizationJson).map((key) => ({
      lable: languageNames[key],
      value: key,
    }));

    setAvailableSiteLanguages(languageArray);
  }, [localizationJson]);

  const handleSelectLang = (value: string | string[]) => {
    setLangages(value);
    setUserUpdateData((prev) => ({ ...prev, preferredLanguages: value }));
  };

  const compressFiles = (
    file: File,
    setCompressedFiles: React.Dispatch<React.SetStateAction<File | null>>
  ): void => {
    /*  setActionLoaders((prev) => ({ ...prev, pickAvatarLoader: true })); */
    new Compressor(file, {
      quality: 0.6,
      success(result) {
        const compressedFile = new File([result], file.name, {
          type: result.type,
          lastModified: Date.now(),
        });

        if (compressedFile) {
          setCompressedFiles(compressedFile);
          /*  setActionLoaders((prev) => ({ ...prev, pickAvatarLoader: false })); */
          setSettingsAvatarModal(true);
        }
      },
      error(err) {
        console.error(err.message);
        /*   setActionLoaders((prev) => ({ ...prev, pickAvatarLoader: false })); */
      },
    });
  };

  const handleAvatarChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target && event.target.files) {
      const file = event.target.files[0];
      if (file) {
        compressFiles(file, setAvatarFile);
      }
    }
  };
  useEffect(() => {
    setUserUpdateData((prev) => ({ ...prev, dateOfBirth: dateOfBirth }));
  }, [dateOfBirth]);

  useEffect(() => {
    if (profileUserData && profileUserData.dateOfBirth) {
      setDateOfBirth(new Date(profileUserData.dateOfBirth));
    }
    if (profileUserData && profileUserData.preferredLanguages)
      setLangages(profileUserData.preferredLanguages);
  }, [profileUserData]);

  useEffect(() => {
    if (!userData || !userData.location) return;
    const country = userData.location.split(", ")[1];
    const userCountry = countries.getAlpha2Code(country, "en");
    setSelectedCountry(userCountry ? userCountry : "");
  }, [userData]);

  const changeLangHandler = async (value: string) => {
    if (!token || !userData) return;
    const resposne = await UsersApi.updateUserData(token, {
      _id: userData._id,
      selectedLanguage: value,
    });

    if (resposne.status) {
      setUserData((prev) => {
        if (!prev) return prev;
        return {
          ...prev,
          selectedLanguage: value,
        };
      });
      setSelectLang(value);
    }
  };

  const connectGoogleCalendar = async () => {
    if (!token) return;
    const response = await NotificationsApi.generateCode(token);
    if (response.status && response.url) {
      window.open(response.url);
    }
  };

  return (
    <div className={s.container}>
      <div className={s.leftProfileBlock}>
        <div className={s.headerEditBlock}>
          <span></span>{" "}
          <button className={s.saveProfileButton} onClick={updateData}>
            <span>{strings.profileSaveBtn}</span>
          </button>
        </div>
        <div className={s.mainEditBlock}>
          <div className={s.avatarBlock}>
            <div style={{ position: "relative" }}>
              <div className={s.avatarImageContainer}>
                {avatarFile ? (
                  <img
                    src={URL.createObjectURL(avatarFile)}
                    alt=""
                    className={s.avatarImg}
                  />
                ) : (
                  <CircleAvatar
                    userId={
                      profileUserData
                        ? profileUserData._id
                        : userData
                        ? userData._id
                        : ""
                    }
                    width="100px"
                    height="100px"
                    fontSize="52px"
                  />
                )}
              </div>

              <label>
                <span className={s.updateAvatarBtn} role="button">
                  <img src={EditIcon} alt="" />
                  <span>{strings.replace}</span>
                </span>
                <input
                  style={{ display: "none" }}
                  type="file"
                  accept="image/*"
                  onChange={(e) => handleAvatarChange(e)}
                />
              </label>
            </div>
          </div>
          <div className={s.personInfoContainer}>
            <div className={`${s.personInfoBlock} ${s.editPersonInfoBlock}`}>
              <div
                className={`${s.personInfoLineBlock} ${s.editPersonInfoLineBlock}`}
              >
                <div
                  className={`${s.personInfoElement} ${s.editPersonInfoElement}`}
                >
                  <span className={s.infoLable}>
                    {strings.firstNameProfileLable}
                  </span>

                  <Input
                    inputValue={
                      userUpdateData.name
                        ? userUpdateData.name
                        : profileUserData?.name
                    }
                    onChangeInput={(value) =>
                      onChangeSpecialistDataText(value, "name", 32)
                    }
                    isVisible
                    required
                  />
                </div>
                <div
                  className={`${s.personInfoElement} ${s.editPersonInfoElement}`}
                >
                  <span className={s.infoLable}>
                    {strings.lastNameProfileLable}
                  </span>

                  <Input
                    inputValue={
                      userUpdateData.surname
                        ? userUpdateData.surname
                        : profileUserData?.surname
                    }
                    onChangeInput={(value) =>
                      onChangeSpecialistDataText(value, "surname", 32)
                    }
                    isVisible
                    required
                  />
                </div>
              </div>
            </div>
            <div className={`${s.personInfoBlock} ${s.editPersonInfoBlock}`}>
              <div
                className={`${s.personInfoLineBlock} ${s.editPersonInfoLineBlock}`}
              >
                <div
                  className={`${s.personInfoElement} ${s.editPersonInfoElement}`}
                >
                  <span className={s.infoLable}>
                    {strings.fromCityProfileLable}
                  </span>

                  <div className={s.countryBlock}>
                    <ReactFlagsSelect
                      blacklistCountries
                      countries={["RU"]}
                      className={s.countrySelect}
                      selected={selectedCountry}
                      onSelect={(code) => setSelectedCountry(code)}
                      searchable
                      placeholder={
                        selectedCountry.length ? "" : "Select country"
                      }
                    />
                  </div>
                </div>

                <div
                  className={`${s.personInfoElement} ${s.editPersonInfoElement}`}
                >
                  <span className={s.infoLable}>
                    {strings.fromCountryProfileLable}
                  </span>
                  <div className={s.cityPickerBlock}>
                    <CityPicker
                      selectedCity={selectedCity}
                      selectedCountry={selectedCountry}
                      onCitySelect={setSelectedCity}
                      defaultValue={userData?.location}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className={`${s.personInfoBlock} ${s.editPersonInfoBlock}`}>
              <div
                className={`${s.personInfoLineBlock} ${s.editPersonInfoLineBlock}`}
              >
                <div
                  className={`${s.personInfoElement} ${s.editPersonInfoElement}`}
                >
                  <span className={s.infoLable}>
                    {strings.dateBirthProfileLable}
                  </span>

                  <div className={s.dateOfBirthBlock}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        className={s.datePicker}
                        value={dayjs(dateOfBirth)}
                        onChange={(value: Dayjs | null) =>
                          setDateOfBirth(value ? value.toDate() : null)
                        }
                      />
                    </LocalizationProvider>{" "}
                    <div></div>
                  </div>
                </div>
                <div
                  className={`${s.personInfoElement} ${s.editPersonInfoElement}`}
                >
                  <div>
                    <span className={s.infoLable}>
                      {strings.langProfileLable}
                    </span>

                    <MultipleSelect
                      data={languagesList}
                      setValue={handleSelectLang}
                      value={langages}
                      multiplie={true}
                      padding="10px 20px"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={s.rightProfileBlock}>
        {availableSiteLanguages && (
          <div className={s.selectLangBlock}>
            <span className={s.infoLable}>{strings.languageTitle}</span>
            <MultipleSelect
              data={availableSiteLanguages}
              multiplie={false}
              setValue={(value) => {
                changeLangHandler(value as string);
              }}
              value={selectLang ? selectLang : ""}
              width="100%"
            />
          </div>
        )}
        <div className={s.timeZoneSelectBlock}>
          <span className={s.infoLable}>{strings.timezoneProfileLable}</span>

          <MultipleSelect
            data={options.map((option) => ({
              value: option.value,
              lable: option.label,
            }))}
            multiplie={false}
            setValue={(value) => onChangeSpecialistDataText(value, "timezone")}
            value={
              userUpdateData.timezone
                ? userUpdateData.timezone
                : profileUserData?.timezone
            }
            padding="10px 20px"
          />
        </div>
        <div className={s.integrationsBlock}>
          <div className={s.infoTitleBlock}>
            <span>{strings.intagrationProfile}</span>
          </div>

          <div className={s.integrationsElement}>
            <p className={s.infoLable}>{strings.connectGoogleProfile}</p>
            <Tooltip
              title={calendarIsConnected ? strings.connectGoogleTooltip : ""}
              placement="right"
              arrow
            >
              <div
                style={{
                  width: "fit-content",
                  marginTop: "10px",
                }}
              >
                <StandartButton
                  buttonTitle={strings.connectProfileBtn}
                  action={connectGoogleCalendar}
                  width="120px"
                  disabled={calendarIsConnected}
                />
              </div>
            </Tooltip>
          </div>
        </div>
      </div>
      {avatarFile && (
        <CropAvatarModal
          avatar={avatarFile}
          modalVisible={settingsAvatarModal}
          setAvatar={setAvatarFile}
          setModalVisible={setSettingsAvatarModal}
        />
      )}
    </div>
  );
};

export default EditClientProfile;
