import { Skeleton } from "@mui/material";
import { useEffect, useState } from "react";
import UsersApi from "../../api/usersApi";
import ModalWindow from "../ModalWindow/ModalWindow";
import s from "./CircleAvatar.module.css";

type CircleAvatarProps = {
  userId?: string;
  width?: string;
  height?: string;
  fontSize?: string;
  marginRight?: string;
  name?: string;
  surname?: string;
  avatarProps?: string;
  onClick?: (arg?: any) => void;
  border?: string;
  isModalAvatar?: boolean;
  borderRadius?: string;
  hideNoAvtaarBlock?: boolean;
  isPubcicData?: boolean;
};

const CircleAvatar = ({
  userId,
  width,
  height,
  fontSize,
  name,
  surname,
  marginRight,
  avatarProps,
  onClick,
  border,
  isModalAvatar,
  borderRadius,
  hideNoAvtaarBlock,
  isPubcicData = true,
}: CircleAvatarProps) => {
  const token = localStorage.getItem("token");
  const [avatar, setAvatar] = useState<string | undefined>();
  const [isLoader, setIsLoader] = useState(false);
  const [nameSurname, setNameSurname] = useState({ name: "", surname: "" });
  const [modalAvatar, setModalAvatar] = useState(false);

  useEffect(() => {
    const makeAsync = async () => {
      if (!userId) return;
      setIsLoader(true);
      try {
        const [responseNotPublic, responsePublic] = await Promise.all([
          token && UsersApi.getUserAvatarAndName(token!, userId),
          UsersApi.getUserPublicAvatarAndName(userId),
        ]);

        const response = isPubcicData ? responsePublic : responseNotPublic;

        if (response && response.name && response.surname) {
          setNameSurname({
            name: response.name,
            surname: response.surname,
          });
        }

        if (response && response.avatar) {
          setAvatar(response.avatar);
        }
      } catch (error) {
        console.error("Error fetching user data:", error);
      } finally {
        setIsLoader(false);
      }
    };

    makeAsync();
  }, [userId]);

  return (
    <div
      className={s.container}
      onClick={
        onClick
          ? onClick
          : () => {
              return;
            }
      }
    >
      {avatar || avatarProps ? (
        isLoader ? (
          <Skeleton
            height={height ?? "50px"}
            width={width ?? "50px"}
            variant="circular"
            animation="wave"
            style={{ marginRight: marginRight ? marginRight : "10px" }}
          />
        ) : (
          <img
            onClick={
              isModalAvatar
                ? () => setModalAvatar((prev) => !prev)
                : () => {
                    return;
                  }
            }
            src={avatarProps ? avatarProps : avatar}
            alt=""
            className={s.avatar}
            style={{
              width: width ? width : "50px",
              height: height ? height : "50px",
              marginRight: marginRight ? marginRight : "10px",
              border: border ?? "none",
              borderRadius: borderRadius ?? "50%",
              aspectRatio: 1 / 1,
            }}
          />
        )
      ) : (
        !hideNoAvtaarBlock && (
          <span
            className={s.emptyAvatarBlock}
            style={{
              width: width ? width : "50px",
              height: height ? height : "50px",
              fontSize: fontSize ? fontSize : "24px",
              marginRight: marginRight ? marginRight : "10px",
              border: border ?? "none",
              borderRadius: borderRadius ?? "50%",
              aspectRatio: 1 / 1,
            }}
          >
            {nameSurname.name ? nameSurname.name[0] : name ? name[0] : ""}
            {nameSurname.surname
              ? nameSurname.surname[0]
              : surname
              ? surname[0]
              : ""}
          </span>
        )
      )}
      <ModalWindow
        isOpen={modalAvatar}
        setIsOpen={setModalAvatar}
        maxWidth="fit-content"
        bgColor="transparent"
        showCloseBtn={false}
      >
        <img
          src={avatarProps ? avatarProps : avatar}
          alt=""
          className={s.modalAvatar}
        />
      </ModalWindow>
    </div>
  );
};

export default CircleAvatar;
