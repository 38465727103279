import { network } from "../config";
import { Payment } from "../types/Balance";
import TypeDefaultResponse from "../types/TypeDefaultResponse";
import { SpecialistData } from "../types/TypeSpecialist";
import {
  Genders,
  GetUserAvatar,
  TypeConfirmRegister,
  TypeGeoInfo,
  TypeNewUser,
  TypeUpdatedUserInfo,
  TypeUserData,
} from "../types/TypeUsers";
import { callApi } from "./apiUtils";
const { users } = network;

const baseURL = process.env.REACT_APP_HTTP_CONNECTION_STRING + "users/";

const getAuthHeader = (token: string) => {
  return { Authorization: `Bearer ${token}` };
};

export default class UsersApi {
  static async register(
    registerData: TypeNewUser
  ): Promise<TypeDefaultResponse & { userData?: TypeUserData | null }> {
    return callApi(`${baseURL}${users.register}`, {
      method: "POST",
      json: { registerData },
    });
  }

  static async resendConfCode(payload: {
    userId: string;
  }): Promise<TypeDefaultResponse & { userData?: TypeUserData | null }> {
    return callApi(`${baseURL}${users.resendConfCode}`, {
      method: "POST",
      json: payload,
    });
  }

  static async getUserById(
    token: string,
    id: string
  ): Promise<
    TypeDefaultResponse & { userData?: TypeUserData | null; token?: string }
  > {
    return callApi(`${baseURL}${users.getUserById}/${id}`, {
      headers: getAuthHeader(token),
    });
  }

  static async getUserSelectedFields(
    token: string,
    userId: string,
    selectUserFields: string,
    selectSpecFields: string
  ): Promise<TypeDefaultResponse & { userData?: Partial<TypeUserData> }> {
    return callApi(
      `${baseURL}${users.getUserSelectedFields}/${userId}/${selectUserFields}/${selectSpecFields}`,
      {
        headers: getAuthHeader(token),
      }
    );
  }

  static async getUserProfileInfoById(
    token: string,
    id: string
  ): Promise<
    TypeDefaultResponse & {
      user?: (TypeUserData & { specialistId?: SpecialistData }) | null;
    }
  > {
    return callApi(`${baseURL}${users.getUserProfileInfoById}/${id}`, {
      headers: getAuthHeader(token),
    });
  }

  static async getUserPublicInfo(
    username: string,
    selectedLanguage: string,
    token?: string
  ): Promise<
    TypeDefaultResponse & {
      user?: (TypeUserData & { specialistIds?: SpecialistData[] }) | null;
    }
  > {
    return callApi(
      `${baseURL}${users.getUserPublicInfo}/${username}/${selectedLanguage}`,
      {
        headers: token ? getAuthHeader(token) : undefined,
      }
    );
  }

  static async getUserPublicInfoById(
    userIdToGetInfo: string,
    selectedLanguage: string,
    token?: string
  ): Promise<
    TypeDefaultResponse & {
      user?: (TypeUserData & { specialistIds?: SpecialistData[] }) | null;
    }
  > {
    return callApi(
      `${baseURL}${users.getUserPublicInfoById}/${userIdToGetInfo}/${selectedLanguage}`,
      {
        headers: token ? getAuthHeader(token) : undefined,
      }
    );
  }

  static async getUserByToken(token: string): Promise<TypeUserData | null> {
    return callApi(`${baseURL}${users.getUserByToken}/${token}`, {
      headers: getAuthHeader(token),
    });
  }

  static async auth(
    phoneNumberOrEmail: string,
    password: string,
    selectedLanguage: string,
    rememberMe?: boolean
  ): Promise<
    TypeDefaultResponse & {
      userData?: TypeUserData | null;
      token?: string;
    }
  > {
    return callApi(`${baseURL}${users.auth}`, {
      method: "POST",
      json: {
        phoneNumberOrEmail: phoneNumberOrEmail,
        password: password,
        selectedLanguage: selectedLanguage,
        rememberMe: rememberMe,
      },
    });
  }

  static async confirmRegister(confirmedData: TypeConfirmRegister): Promise<
    TypeDefaultResponse & {
      userData?: TypeUserData | null;
      token?: string;
    }
  > {
    return callApi(`${baseURL}${users.confirmRegister}`, {
      method: "POST",
      json: confirmedData,
    });
  }

  static async confirmPassUpdate(
    email: string
  ): Promise<TypeDefaultResponse & { id: string }> {
    return callApi(`${baseURL}${users.confirmPassUpdate}`, {
      method: "POST",
      json: { email },
    });
  }

  static async updatePassword(updateData: {
    password: string;
    id: string;
    confirmationCode: number;
  }): Promise<
    TypeDefaultResponse & {
      password: string;
      id: string;
      confirmationCode: number;
    }
  > {
    return callApi(`${baseURL}${users.updatePassword}`, {
      method: "POST",
      json: updateData,
    });
  }

  static async updateUserData(
    token: string,
    updateData: TypeUpdatedUserInfo
  ): Promise<
    TypeDefaultResponse & {
      userData?: TypeUserData | null;
    }
  > {
    return callApi(`${baseURL}${users.update}/${updateData._id}`, {
      headers: getAuthHeader(token),
      method: "POST",
      json: { updateData },
    });
  }

  static async updateAvatar(
    token: string,
    formData: FormData,
    userId: string
  ): Promise<
    TypeDefaultResponse & {
      userData?: TypeUserData | null;
    }
  > {
    return callApi(`${baseURL}${users.updateAvatar}/${userId}`, {
      headers: getAuthHeader(token),
      method: "POST",
      body: formData,
      timeout: 60000,
    });
  }

  static async getUserAvatarAndName(
    token: string,
    id: string
  ): Promise<GetUserAvatar> {
    return callApi(`${baseURL}${users.getUserAvatarAndName}/${id}`, {
      headers: getAuthHeader(token),
    });
  }

  static async getUserPublicAvatarAndName(id: string): Promise<GetUserAvatar> {
    return callApi(`${baseURL}${users.getUserPublicAvatarAndName}/${id}`, {});
  }

  static async checkIfUserJoinedAnyCompany(
    token: string,
    userId: string
  ): Promise<GetUserAvatar> {
    return callApi(`${baseURL}${users.checkIfUserJoinedAnyCompany}/${userId}`, {
      headers: getAuthHeader(token),
    });
  }

  static async getAllGenders(token: string): Promise<
    TypeDefaultResponse & {
      genders?: Genders[];
    }
  > {
    return callApi(`${baseURL}${users.getAllGenders}`, {
      headers: getAuthHeader(token),
    });
  }

  static async getUserPayments(
    token: string,
    userId: string,
    page: number,
    limit: number
  ): Promise<
    TypeDefaultResponse & {
      payments: Payment[];
    }
  > {
    return callApi(
      `${baseURL}${users.getUserPayments}/${userId}/${page}/${limit}`,
      {
        headers: getAuthHeader(token),
      }
    );
  }

  static async fixScheduleType(token: string): Promise<
    TypeDefaultResponse & {
      payments: Payment[];
    }
  > {
    return callApi(`${baseURL}${users.fixScheduleType}`, {
      headers: getAuthHeader(token),
    });
  }

  static async addFavouriteUser(
    token: string,
    userId: string,
    favouriteUserId: string
  ): Promise<TypeDefaultResponse> {
    return callApi(`${baseURL}${users.addFavouriteUser}`, {
      headers: getAuthHeader(token),
      method: "POST",
      json: { userId, favouriteUserId },
      timeout: 60000,
    });
  }

  static async removeFavouriteUser(
    token: string,
    userId: string,
    favouriteUserId: string
  ): Promise<TypeDefaultResponse> {
    return callApi(`${baseURL}${users.removeFavouriteUser}`, {
      headers: getAuthHeader(token),
      method: "POST",
      json: { userId, favouriteUserId },
      timeout: 60000,
    });
  }

  static async getUsersFavouritesList(
    token: string,
    userId: string,
    page: number,
    limit: number
  ): Promise<
    TypeDefaultResponse & {
      payments: Payment[];
    }
  > {
    return callApi(
      `${baseURL}${users.getUsersFavouritesList}/${userId}/${page}/${limit}`,
      {
        headers: getAuthHeader(token),
      }
    );
  }

  static async checkIfUserIsFavourite(
    token: string,
    specialistUserId: string
  ): Promise<
    TypeDefaultResponse & {
      isFavourite?: boolean;
    }
  > {
    return callApi(
      `${baseURL}${users.checkIfUserIsFavourite}/${specialistUserId}`,
      {
        headers: getAuthHeader(token),
      }
    );
  }

  static async checkIfUserWorkedWithSpecialist(
    token: string,
    specialistUserId: string
  ): Promise<
    TypeDefaultResponse & {
      worked: boolean;
      commonSessionsAmount?: number;
    }
  > {
    return callApi(
      `${baseURL}${users.checkIfUserWorkedWithSpecialist}/${specialistUserId}`,
      {
        headers: getAuthHeader(token),
      }
    );
  }

  static async getUserPublicInfoLatinName(
    partialUserIdToGetInfo: string,
    name: string,
    surname: string,
    selectedLanguage: string
  ): Promise<
    TypeDefaultResponse & {
      user: TypeUserData;
    }
  > {
    return callApi(
      `${baseURL}${users.getUserPublicInfoLatinName}/${partialUserIdToGetInfo}/${name}/${surname}/${selectedLanguage}`
    );
  }
  static async connectPhoneNumber(
    token: string,
    phoneNumber: string,
    selectedLanguage: string
  ): Promise<TypeDefaultResponse & { userData: TypeUserData }> {
    return callApi(`${baseURL}${users.connectPhoneNumber}`, {
      headers: getAuthHeader(token),
      method: "POST",
      json: { phoneNumber, selectedLanguage },
      timeout: 60000,
    });
  }

  static async getUserGeoInfo(): Promise<
    TypeDefaultResponse & {
      geo?: TypeGeoInfo;
    }
  > {
    return callApi(`${baseURL}${users.getUserGeoInfo}`);
  }
}
