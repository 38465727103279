import { CircularProgress, ThemeProvider, Tooltip } from "@mui/material";
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
import countries from "i18n-iso-countries";
import { useContext, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ReactSpoiler } from "react-simple-spoiler";
import { ToastContainer } from "react-toastify";
import "swiper/css";
import "swiper/css/pagination";
import SessionsApi from "../../api/sessionApi";
import SpecialistApi from "../../api/specialistApi";
import UsersApi from "../../api/usersApi";
import { ReactComponent as ChevronDown } from "../../assets/Profile/specialist/chevron-down.svg";
import { ReactComponent as SpecialistyReviewsIcon } from "../../assets/Profile/specialist/comments.svg";
import { ReactComponent as EearthIcon } from "../../assets/Profile/specialist/earh.svg";
import { ReactComponent as SpecializationIcon } from "../../assets/Profile/specialist/global.svg";
import { ReactComponent as GreenStar } from "../../assets/Profile/specialist/green-star.svg";
import { ReactComponent as MessageIcon } from "../../assets/Profile/specialist/message.svg";
import { ReactComponent as PlayBtnIcon } from "../../assets/Profile/specialist/play-circle-video.svg";
import { ReactComponent as SpecialitySessionIcon } from "../../assets/Profile/specialist/sessions.svg";
import { ReactComponent as Token } from "../../assets/Profile/specialist/token-icon.svg";
import { ReactComponent as TotalEducationIcon } from "../../assets/Profile/specialist/total-education.svg";
import { ReactComponent as TotalFederationsIcon } from "../../assets/Profile/specialist/total-federations.svg";
import { ReactComponent as TotalQualificationsIcon } from "../../assets/Profile/specialist/total-qualifications.svg";
import { ReactComponent as VerifiedIcon } from "../../assets/Profile/specialist/verify.svg";
import CircleAvatar from "../../components/CircleAvatar/CircleAvatar";
import ModalWindow from "../../components/ModalWindow/ModalWindow";
import StandartButton from "../../components/StandartButton/StandartButton";
import { ContextProvider } from "../../contextProvider";
import strings from "../../localization";
import localizationJson from "../../localization.json";
import { SpecialistLevels } from "../../types/Subscription";
import { SessionComment } from "../../types/TypeSession";
import { Badge, SpecialistData } from "../../types/TypeSpecialist";
import { TypeUserData } from "../../types/TypeUsers";
import { calculateAge } from "../../utils/setupProfileInfo";
import { theme } from "../../utils/theme";
import Notify from "../../utils/toaster";
import EducationSection from "../Profile/SpecialistProfileContent/EducationSection/EducationSection";
import ReviewsSection from "../Profile/SpecialistProfileContent/ReviewsSection/ReviewsSection";
import s from "./PublicProfile.module.css";

countries.registerLocale(require("i18n-iso-countries/langs/en.json"));

export type CertificatesData = {
  _id?: string | undefined;
  filename: string | undefined;
  type: string | undefined;
  size: number | undefined;
  link: string;
};

const PublicProfile = () => {
  const connectionString = process.env.REACT_APP_HTTP_CONNECTION_STRING;
  const storageSelectLang = localStorage.getItem("lang");
  const { selectLang, setSelectLang } = useContext(ContextProvider);
  const navigate = useNavigate();
  const [videoModalOpen, setVideoModalOpen] = useState(false);
  const [loaders, setLoaders] = useState({
    mainLoader: false,
    certificates: false,
  });
  const [navigateToRegModal, setNavigateToRegModal] = useState(false);
  const [profileUserData, setProfileUserData] = useState<
    (TypeUserData & { specialistId?: SpecialistData }) | undefined | null
  >();
  const [specialistData, setSpecialistData] = useState<
    | {
        specializationValue: string;
        specializationLable: string;
        specialistData: SpecialistData;
      }[]
    | undefined
  >();
  const [selectedSpecialistData, setSelectedSpecialistData] = useState<
    | {
        specializationValue: string;
        specializationLable: string;
        specialistData: SpecialistData;
      }
    | undefined
  >();
  const [badges, setBadges] = useState<Badge[] | undefined>();
  const [commentsData, setCommentsData] = useState<{
    [id: string]: SessionComment[];
  }>();
  const [
    totalCurrentSpecializationSessions,
    setTotalCurrentSpecializationSessions,
  ] = useState<{ [id: string]: number }>();
  const [videoPreviewImg, setVideoPreviewImg] = useState<string | undefined>();
  const [textHeight, setTextHeight] = useState({
    aboutMyself: 0,
    aboutTeraphy: 0,
  });

  const [totalInfo, setTotalInfo] = useState<{
    reviewTotal?: number;
    sessionsTotal?: number;
  }>({});

  const introVideoId =
    selectedSpecialistData &&
    selectedSpecialistData.specialistData.introductionVideo?._id
      ? selectedSpecialistData.specialistData.introductionVideo._id
      : null;

  const aboutMyselfRef = useRef<HTMLParagraphElement | null>(null);
  const aboutTeraphyRef = useRef<HTMLParagraphElement | null>(null);
  const videoRef = useRef<HTMLVideoElement | null>(null);
  const canvasRef = useRef<HTMLCanvasElement | null>(null);
  const imgRef = useRef<HTMLImageElement | null>(null);

  const pathname = window.location.pathname;
  const pathParts = pathname.replace("/", "").split("-");
  const latinName = pathParts[0];
  const latinSurname = pathParts[1];
  const partialUserIdToGetInfo = pathParts[2];

  const coachLevels = {
    [SpecialistLevels.STUDENT]: strings.specLvlStudent,
    [SpecialistLevels.GRADUATED_STUDENT]: strings.specLvlGradtudent,
    [SpecialistLevels.LEVEL1]: strings.specLvl1,
    [SpecialistLevels.LEVEL2]: strings.specLvl2,
  };
  useEffect(() => {
    if (!aboutMyselfRef.current || !aboutTeraphyRef.current) return;
    setTextHeight({
      aboutMyself: aboutMyselfRef.current.offsetHeight + 10,
      aboutTeraphy: aboutTeraphyRef.current.offsetHeight + 10,
    });
  }, [aboutMyselfRef.current, aboutTeraphyRef.current]);

  useEffect(() => {
    const browserLanguage = navigator.language.split("-")[0];
    const languageArray = Object.keys(localizationJson);

    if (languageArray.includes(browserLanguage)) {
      setSelectLang(browserLanguage);
    }

    setSelectLang((prev) => (prev ? prev : "en"));
  }, []);

  useEffect(() => {
    const makeAsync = async () => {
      if (
        latinName === "" ||
        latinSurname === "" ||
        partialUserIdToGetInfo === ""
      ) {
        return navigate("/choice-mode");
      }

      setLoaders((prev) => ({ ...prev, mainLoader: true }));
      const response = await UsersApi.getUserPublicInfoLatinName(
        partialUserIdToGetInfo,
        latinName,
        latinSurname,
        selectLang ?? "en"
      );
      setLoaders((prev) => ({ ...prev, mainLoader: false }));

      if (!response.status) {
        Notify(response.message!);
        await new Promise((resolve) => setTimeout(resolve, 3000));
        navigate("/choice-mode");
      }
      if (response.status && response.user && response.user.specialistIds) {
        setProfileUserData(response.user);

        setSpecialistData(
          response.user.specialistIds &&
            response.user.specialistIds.map((item) => ({
              specializationValue: item.mainSpecializations[0].specialization,
              specializationLable: item.mainSpecializations[0].labels.find(
                (el) => el.language === (storageSelectLang ?? "en")
              )!.text,
              specialistData: item,
            }))
        );
      }
    };
    makeAsync();
  }, []);
  useEffect(() => {
    if (!specialistData) return;
    setSelectedSpecialistData(specialistData[0]);
  }, [specialistData]);
  useEffect(() => {
    const video = videoRef.current;
    const canvas = canvasRef.current;
    const img = imgRef.current;

    if (!video || !canvas || !img) return;

    const handleLoadedMetadata = () => {
      video.currentTime = 0;
    };

    const handleTimeUpdate = () => {
      const context = canvas.getContext("2d");
      canvas.width = video.videoWidth;
      canvas.height = video.videoHeight;
      if (!context) return;
      context.drawImage(video, 0, 0, canvas.width, canvas.height);
      const dataURL = canvas.toDataURL();

      setVideoPreviewImg(dataURL);
    };

    video.addEventListener("loadedmetadata", handleLoadedMetadata);
    video.addEventListener("timeupdate", handleTimeUpdate);

    return () => {
      video.removeEventListener("loadedmetadata", handleLoadedMetadata);
      video.removeEventListener("timeupdate", handleTimeUpdate);
    };
  }, [profileUserData, selectedSpecialistData]);

  const getSpecialistsSessionsAmount = async (specialistId?: string) => {
    if (!profileUserData) return;
    const response = await SessionsApi.getSpecialistsSessionsAmount(
      profileUserData._id,
      specialistId
    );

    if (response.status && !specialistId) {
      setTotalInfo((prev) => ({ ...prev, sessionsTotal: response.amount }));
    }
    if (response.status && specialistId) {
      setTotalCurrentSpecializationSessions((prev) => ({
        ...prev,
        [specialistId]: response.amount,
      }));
    }
  };
  const getSpecialistsComments = async (specialistId?: string) => {
    if (!profileUserData || !profileUserData._id || !specialistData) return;

    const commentsResponse = await SessionsApi.getSpecialistsComments(
      profileUserData._id,
      15,
      1,
      specialistId
    );

    if (!specialistId && commentsResponse.status) {
      setTotalInfo((prev) => ({
        ...prev,
        reviewTotal: commentsResponse.totalAmount,
      }));
    }
    if (commentsResponse.status && specialistId) {
      setCommentsData((prev) => ({
        ...prev,
        [specialistId]: commentsResponse.comments,
      }));
    }
  };
  const getAllSpecialistUserBadges = async (specialistUserId: string) => {
    const response = await SpecialistApi.getAllSpecialistUserBadges(
      specialistUserId
    );

    if (response.status) {
      setBadges(response.badges);
    }
  };

  useEffect(() => {
    if (!profileUserData) return;
    getAllSpecialistUserBadges(profileUserData._id);
    getSpecialistsComments();
    getSpecialistsSessionsAmount();
    if (!specialistData) return;
    getSpecialistsSessionsAmount(selectedSpecialistData?.specialistData._id);
    getSpecialistsComments(selectedSpecialistData?.specialistData._id);
  }, [specialistData]);

  const changeLangHandler = async (value: string) => {
    strings.setLanguage(value);
    setSelectLang(value);
  };

  const navigateToAuth = (authMode: "login" | "signup") => {
    localStorage.setItem("profilePathName", pathname);
    navigate("/login", {
      state: {
        authMode: authMode,
      },
    });
  };

  if (loaders.mainLoader || !profileUserData || !selectedSpecialistData) {
    return (
      <div className="loader-container">
        <ThemeProvider theme={theme}>
          <CircularProgress size={150} color="primary" />
        </ThemeProvider>
        <ToastContainer />
      </div>
    );
  }

  return (
    <div className={s.container} id="profile-content">
      <div className={s.langSelectBlock}>
        <button
          onClick={() => changeLangHandler("en")}
          className={selectLang === "en" ? s.selectLang : s.unselectLang}
        >
          EN
        </button>
        <button
          onClick={() => changeLangHandler("uk")}
          className={selectLang === "uk" ? s.selectLang : s.unselectLang}
        >
          УКР
        </button>
      </div>

      <div className={s.profileInfoBlock}>
        <div className={s.headBlock}>
          <div className={s.headBlockMainContent}>
            <div className={s.headNameBlock}>
              <div className={s.mobileAvatarBlock}>
                <CircleAvatar
                  userId={profileUserData?._id || ""}
                  name={profileUserData?.name || ""}
                  surname={profileUserData?.surname || ""}
                  height="auto"
                  width="100%"
                  fontSize="78px"
                  marginRight="0"
                  isModalAvatar
                  borderRadius="20px"
                  hideNoAvtaarBlock
                  isPubcicData
                />
              </div>
              <div className={s.nameBlock}>
                <div className={s.nameTitleBlock}>
                  <span className={s.nameTitle}>
                    {profileUserData?.name} {profileUserData?.surname}
                  </span>
                </div>
                <span className={s.ageTitle}>
                  {calculateAge(new Date(profileUserData!.dateOfBirth!)) +
                    " " +
                    strings.ageAbbr}
                </span>
                <div className={s.rightMenuInfoBlock}>
                  <SpecializationIcon />
                  <span>
                    {specialistData
                      ? specialistData
                          .map(
                            (item) =>
                              item.specialistData.mainSpecializations[0].labels.find(
                                (item) => item.language === (selectLang ?? "en")
                              )?.text +
                              (item.specialistData.level &&
                              item.specialistData.level.length
                                ? " " +
                                  coachLevels[
                                    item.specialistData.level
                                  ].toLowerCase()
                                : "")
                          )
                          .join(", ")
                      : ""}
                  </span>
                </div>
                <div className={s.rightMenuInfoBlock}>
                  <MessageIcon className={s.infoIcon} width={15} height={15} />
                  <span style={{ color: "black" }}>
                    {profileUserData &&
                      profileUserData.preferredLanguages!.join(", ")}
                  </span>
                </div>
                <div className={s.rightMenuInfoBlock}>
                  <EearthIcon />
                  <span style={{ color: "black" }}>
                    {profileUserData && profileUserData.location}
                  </span>
                </div>
                {badges && badges.length ? (
                  <div className={s.badgesBlock}>
                    {badges.map((item, index) => (
                      <Tooltip arrow title={item.title} key={index}>
                        <a href={item.url} target="_blank">
                          <img src={item.link} alt="" className={s.badge} />
                        </a>
                      </Tooltip>
                    ))}
                  </div>
                ) : null}
              </div>
            </div>
            <div className={s.actionBlock}>
              <div className={s.guestProfileActionBlock}>
                <StandartButton
                  buttonTitle={strings.bookProfileBtn}
                  action={() => setNavigateToRegModal(true)}
                  width="100%"
                />
                <div
                  className={s.writeButton}
                  onClick={() => setNavigateToRegModal(true)}
                >
                  <MessageIcon className={s.messageIcon} />
                </div>
              </div>
            </div>
            {totalInfo.reviewTotal !== undefined && (
              <div className={s.totalInfoBlock}>
                <span>{strings.reviewSpecProfile}</span>
                <span>{totalInfo.reviewTotal}</span>
              </div>
            )}
            {totalInfo.sessionsTotal !== undefined && (
              <div className={s.totalInfoBlock}>
                <span>{strings.totalSessions}</span>
                <span>{totalInfo.sessionsTotal}</span>
              </div>
            )}
            {profileUserData &&
            profileUserData.briefDescription &&
            profileUserData.briefDescription.length ? (
              <div className={s.briefDescription}>
                {profileUserData.briefDescription.find(
                  (el) => el.language === (selectLang ?? "en")
                )?.text ?? profileUserData.briefDescription[0].text}
              </div>
            ) : null}
          </div>
        </div>
        <div className={s.dynamiContentBlock}>
          <div className={s.tabsBlock}>
            {specialistData &&
              specialistData.map((item, index) => (
                <div
                  className={
                    selectedSpecialistData!.specialistData
                      .mainSpecializations[0].specialization ===
                    item.specializationValue
                      ? s.activeTab
                      : s.notActiveTab
                  }
                  onClick={() => {
                    setSelectedSpecialistData(
                      specialistData.find(
                        (el) =>
                          el.specializationValue === item.specializationValue
                      )
                    );
                  }}
                  key={index}
                >
                  <span className={s.specialityTabTitle}>
                    {item.specializationLable}{" "}
                    {item.specialistData.isVerified && (
                      <VerifiedIcon className={s.verifyIcon} />
                    )}
                  </span>
                  <span
                    className={
                      selectedSpecialistData!.specialistData
                        .mainSpecializations[0].specialization ===
                      item.specializationValue
                        ? s.tabActiveIndicator
                        : s.indicatorHidden
                    }
                  ></span>
                </div>
              ))}
          </div>

          <div className={s.mainInfoBlock}>
            {
              <div className={s.headRatingBlock}>
                <div className={s.headRatingValueBlock}>
                  <Token width={25} height={25} />
                  <div>
                    <span className={s.numberInfoValue}>
                      {selectedSpecialistData.specialistData?.price}
                    </span>
                    <span>{strings.presSession}</span>
                  </div>
                </div>
                {commentsData &&
                selectedSpecialistData &&
                commentsData[selectedSpecialistData.specialistData._id] &&
                selectedSpecialistData.specialistData.rating ? (
                  <div className={s.headRatingValueBlock}>
                    <GreenStar width={25} height={25} />
                    <div>
                      <span className={s.numberInfoValue}>
                        {selectedSpecialistData?.specialistData.rating.toFixed(
                          1
                        )}
                      </span>
                      <span>{strings.ratingSpecProfile}</span>
                    </div>
                  </div>
                ) : null}
                {totalCurrentSpecializationSessions &&
                  totalCurrentSpecializationSessions[
                    selectedSpecialistData.specialistData._id
                  ] !== undefined && (
                    <div className={s.headRatingValueBlock}>
                      <SpecialitySessionIcon width={25} height={25} />
                      <div>
                        <span className={s.numberInfoValue}>
                          {
                            totalCurrentSpecializationSessions[
                              selectedSpecialistData.specialistData._id
                            ]
                          }
                        </span>
                        <span>{strings.totalSessions}</span>
                      </div>
                    </div>
                  )}
                {commentsData &&
                  commentsData[selectedSpecialistData.specialistData._id] && (
                    <div className={s.headRatingValueBlock}>
                      <SpecialistyReviewsIcon width={25} height={25} />
                      <div>
                        <span className={s.numberInfoValue}>
                          {
                            commentsData[
                              selectedSpecialistData.specialistData._id
                            ].length
                          }
                        </span>
                        <span>{strings.reviewSpecProfile}</span>
                      </div>
                    </div>
                  )}
              </div>
            }

            <div className={s.headRatingBlock}>
              {selectedSpecialistData.specialistData.educationCertificates &&
              selectedSpecialistData.specialistData.educationCertificates
                .length ? (
                <div className={s.totalCertificateBlock}>
                  <TotalEducationIcon width={25} height={25} />
                  <div>
                    <span className={s.totalCertificatesLable}>
                      {`${strings.totalEducation1} ${selectedSpecialistData.specialistData.educationCertificates.length} ${strings.totalEducation2}`}
                    </span>
                  </div>
                </div>
              ) : null}
              {selectedSpecialistData.specialistData.federationCertificates &&
              selectedSpecialistData.specialistData.federationCertificates
                .length ? (
                <div className={s.totalCertificateBlock}>
                  <TotalFederationsIcon width={25} height={25} />
                  <div>
                    <span className={s.totalCertificatesLable}>
                      {`${strings.totalFederations1} ${selectedSpecialistData.specialistData.federationCertificates.length} ${strings.totalFederations2}`}
                    </span>
                  </div>
                </div>
              ) : null}
              {selectedSpecialistData.specialistData
                .qualificationCertificates &&
              selectedSpecialistData.specialistData.qualificationCertificates
                .length ? (
                <div className={s.totalCertificateBlock}>
                  <TotalQualificationsIcon width={25} height={25} />
                  <div>
                    <span className={s.totalCertificatesLable}>
                      {`${strings.totalQualifications1} ${selectedSpecialistData.specialistData.qualificationCertificates.length} ${strings.totalQualifications2}`}
                    </span>
                  </div>
                </div>
              ) : null}
            </div>
            {selectedSpecialistData.specialistData.contributeToProfession &&
            selectedSpecialistData.specialistData.contributeToProfession
              .length ? (
              <div className={s.aboutTitleBlock}>
                <h4 className={s.blockLable}>
                  {strings.professionContribution}
                </h4>

                <p className={s.aboutTitle} ref={aboutTeraphyRef}>
                  {selectedSpecialistData.specialistData.contributeToProfession.find(
                    (el) => el.language === (selectLang ?? "en")
                  )?.text &&
                  selectedSpecialistData.specialistData.contributeToProfession.find(
                    (el) => el.language === (selectLang ?? "en")
                  )?.text.length
                    ? selectedSpecialistData.specialistData.contributeToProfession.find(
                        (el) => el.language === (selectLang ?? "en")
                      )?.text
                    : selectedSpecialistData.specialistData
                        .contributeToProfession[0].text}
                </p>
              </div>
            ) : null}
            <div className={s.subSpecializationBlock}>
              <h4 className={s.blockLable}>{strings.subSpecLable}</h4>
              <div className={s.subSpecializationList}>
                {selectedSpecialistData.specialistData.subSpecializations.map(
                  (item, index) => (
                    <div className={s.subSpecElement} key={index}>
                      <span className={s.subSpecIndicator}></span>
                      <span className={s.subSpecTitle}>
                        {
                          item.labels.find(
                            (el) => el.language === (selectLang ?? "en")
                          )?.text
                        }
                      </span>
                    </div>
                  )
                )}
              </div>
            </div>
            {selectedSpecialistData.specialistData.tags &&
            selectedSpecialistData.specialistData.tags.filter(
              (el) => el.level === 2
            ).length ? (
              <div className={s.workWithSection}>
                <h4 className={s.blockLable}>{strings.workWithLable}</h4>
                <div className={s.workWithList}>
                  {selectedSpecialistData.specialistData.tags
                    .filter((el) => el.level === 2)
                    .map((item, index) => (
                      <div className={s.workWithElement} key={index}>
                        {
                          item.labels.find(
                            (el) => el.language === (selectLang ?? "en")
                          )?.text!
                        }
                      </div>
                    ))}
                </div>
              </div>
            ) : null}
            <div className={s.aboutVideoBlock}>
              {introVideoId ? (
                <div className={s.previewVideoBlock}>
                  <canvas ref={canvasRef} style={{ display: "none" }} />
                  <img
                    ref={imgRef}
                    src={videoPreviewImg}
                    className={s.previewVideoImg}
                  />
                  <PlayBtnIcon
                    className={s.playBtn}
                    onClick={() => setVideoModalOpen(true)}
                  />
                </div>
              ) : null}
              {(selectedSpecialistData.specialistData?.aboutMyself &&
                selectedSpecialistData.specialistData?.aboutMyself.length) ||
              (selectedSpecialistData.specialistData?.aboutTherapy &&
                selectedSpecialistData.specialistData?.aboutTherapy.length) ? (
                <div className={s.aboutTitleBlock}>
                  <h4 className={s.blockLable}>{strings.aboutMeSpecProfile}</h4>
                  <ReactSpoiler
                    collapsedSize={
                      textHeight.aboutMyself > 260
                        ? 260
                        : textHeight.aboutMyself
                    }
                    toggleContainerStyle={{ height: "fit-content" }}
                    showMoreComponent={
                      <div
                        className={s.showMoreBtn}
                        style={{
                          display:
                            textHeight.aboutMyself > 260 ? "flex" : "none",
                        }}
                      >
                        <p>{strings.showMore}</p> <ChevronDown />
                      </div>
                    }
                    showLessComponent={
                      <div
                        className={s.showMoreBtn}
                        style={{
                          display:
                            textHeight.aboutMyself > 260 ? "flex" : "none",
                        }}
                      >
                        <p>{strings.showLess}</p>
                        <ChevronDown className={s.chevronRotate} />
                      </div>
                    }
                  >
                    <p className={s.aboutTitle} ref={aboutMyselfRef}>
                      {selectedSpecialistData.specialistData?.aboutMyself &&
                      selectedSpecialistData.specialistData?.aboutMyself.length
                        ? selectedSpecialistData.specialistData?.aboutMyself.find(
                            (el) => el.language === (selectLang ?? "en")
                          )?.text ??
                          selectedSpecialistData.specialistData?.aboutMyself[0]
                            .text
                        : selectedSpecialistData.specialistData?.aboutTherapy &&
                          selectedSpecialistData.specialistData?.aboutTherapy
                            .length &&
                          (selectedSpecialistData.specialistData?.aboutTherapy.find(
                            (el) => el.language === (selectLang || "en")
                          )?.text ??
                            selectedSpecialistData.specialistData
                              ?.aboutTherapy[0].text)}
                    </p>
                  </ReactSpoiler>
                </div>
              ) : null}
            </div>

            {selectedSpecialistData.specialistData.aboutTherapy &&
            selectedSpecialistData.specialistData.aboutTherapy.length &&
            selectedSpecialistData.specialistData.aboutMyself &&
            selectedSpecialistData.specialistData.aboutMyself.length ? (
              <div className={s.aboutTitleBlock}>
                <h4 className={s.blockLable}>
                  {strings.howSessionGoesSpecProfile}
                </h4>
                <ReactSpoiler
                  collapsedSize={
                    textHeight.aboutTeraphy > 290
                      ? 300
                      : textHeight.aboutTeraphy
                  }
                  showMoreComponent={
                    <div
                      className={s.showMoreBtn}
                      style={{
                        display:
                          textHeight.aboutTeraphy > 250 ? "flex" : "none",
                      }}
                    >
                      <p>{strings.showMore}</p> <ChevronDown />
                    </div>
                  }
                  showLessComponent={
                    <div
                      className={s.showMoreBtn}
                      style={{
                        display:
                          textHeight.aboutTeraphy > 250 ? "flex" : "none",
                      }}
                    >
                      <p>{strings.showLess}</p>
                      <ChevronDown className={s.chevronRotate} />
                    </div>
                  }
                >
                  <p className={s.aboutTitle} ref={aboutTeraphyRef}>
                    {selectedSpecialistData.specialistData.aboutTherapy.find(
                      (el) => el.language === (selectLang || "en")
                    )?.text ??
                      selectedSpecialistData.specialistData.aboutTherapy[0]
                        .text}
                  </p>
                </ReactSpoiler>
              </div>
            ) : null}
            <div className={s.certificatesBlock}>
              <EducationSection
                specialistId={selectedSpecialistData.specialistData._id}
                userId={profileUserData?._id!}
              />
            </div>

            {commentsData &&
              commentsData[selectedSpecialistData.specialistData._id] && (
                <ReviewsSection
                  commentsData={
                    commentsData[selectedSpecialistData.specialistData._id]
                  }
                />
              )}
          </div>
        </div>
      </div>

      <video
        ref={videoRef}
        src={`${connectionString}specialists/${"getSpecialistPublicIntroVideo"}/${
          selectedSpecialistData?.specialistData._id
        }`}
        style={{ display: "none" }}
        crossOrigin="anonymous"
      />

      <ModalWindow
        isOpen={videoModalOpen}
        setIsOpen={setVideoModalOpen}
        bgColor="transparent"
        width="fit-content"
      >
        <video
          src={`${connectionString}specialists/${"getSpecialistPublicIntroVideo"}/${
            selectedSpecialistData?.specialistData._id
          }`}
          crossOrigin="anonymous"
          controls
          autoPlay
          className={s.video}
        />
      </ModalWindow>
      <ModalWindow
        isOpen={navigateToRegModal}
        setIsOpen={setNavigateToRegModal}
        width="fit-content"
      >
        <div className={s.navigateModalContainer}>
          <h2>{strings.pleaseRegisterOrLoginTitle}</h2>
          <span>{strings.pleaseRegisterOrLoginDescription}</span>
          <div className={s.navigateModalBtnBlock}>
            <StandartButton
              buttonTitle={strings.logInModalBtn}
              action={() => navigateToAuth("login")}
              width="150px"
            />
            <StandartButton
              buttonTitle={strings.registerModalBtn}
              action={() => navigateToAuth("signup")}
              width="150px"
            />
          </div>
        </div>
      </ModalWindow>
      <ToastContainer />
    </div>
  );
};

export default PublicProfile;
