import { Skeleton } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import SessionsApi from "../../../api/sessionApi";
import SpecialistApi from "../../../api/specialistApi";
import UsersApi from "../../../api/usersApi";
import { ReactComponent as TokenIcon } from "../../../assets/Header/token-icon.svg";
import CircleAvatar from "../../../components/CircleAvatar/CircleAvatar";
import ModalWindow from "../../../components/ModalWindow/ModalWindow";
import NavigateToProfile from "../../../components/NavigateToProfile/NavigateToProfile";
import SelectSessionDate from "../../../components/SelectSessionDate/SelectSessionDate";
import StandartButton from "../../../components/StandartButton/StandartButton";
import { ContextProvider } from "../../../contextProvider";
import strings from "../../../localization";
import { TypeUserData } from "../../../types/TypeUsers";
import { createChat } from "../../../utils/general";
import Notify, { SuccesNotify } from "../../../utils/toaster";
import s from "./ProgramSpecialistCard.module.css";

type ProgramSpecialistCardProps = {
  userId: string;
  bgColor: string;
  projectId: string;
  discount: number;
};

const ProgramSpecialistCard = ({
  userId,
  bgColor,
  projectId,
  discount,
}: ProgramSpecialistCardProps) => {
  const token = localStorage.getItem("token");
  const { userData } = useContext(ContextProvider);
  const [specialistData, setSpecialistData] = useState<TypeUserData | null>(
    null
  );
  const [bookingModalIsOpen, setBookingModalIsOpen] = useState(false);
  const [overlapTime, setOverlapTime] = useState<{ start: Date; end: Date }[]>([
    { start: new Date(), end: new Date() },
  ]);
  const [latinFullname, setLatinFullname] = useState<{
    latinName: string;
    latinSurname: string;
  } | null>(null);

  const [sessionPrice, setSessionPrice] = useState<{
    priceWithDiscount: string;
    generalPrice: string;
  } | null>(null);
  const [actionLoaders, setActionLoaders] = useState({
    laodSpecialists: false,
  });

  useEffect(() => {
    if (
      !specialistData ||
      !specialistData.specialistIds ||
      !specialistData.specialistIds[0].price
    )
      return;
    const price = specialistData.specialistIds[0].price;

    const newPrice =
      discount === 100
        ? 0
        : discount === 0
        ? price
        : (price - price * (discount / 100)).toFixed(0);

    setSessionPrice({
      priceWithDiscount: newPrice.toString(),
      generalPrice: price.toString(),
    });
  }, [discount, specialistData]);

  useEffect(() => {
    const makeAsync = async () => {
      if (!token) return;
      setActionLoaders((prev) => ({ ...prev, laodSpecialists: true }));
      const response = await UsersApi.getUserPublicAvatarAndName(userId);
      if (response.latinName && response.latinSurname) {
        setLatinFullname({
          latinName: response.latinName,
          latinSurname: response.latinSurname,
        });
      }
      setActionLoaders((prev) => ({ ...prev, laodSpecialists: false }));
    };
    makeAsync();
  }, [token, userId]);

  useEffect(() => {
    if (!token || !userData || !latinFullname) return;
    const makeAsync = async () => {
      setActionLoaders((prev) => ({ ...prev, laodSpecialists: true }));
      const userDataResponse = await UsersApi.getUserPublicInfoLatinName(
        userId.slice(-7),
        latinFullname.latinName,
        latinFullname.latinSurname,
        userData.selectedLanguage
      );

      setActionLoaders((prev) => ({ ...prev, laodSpecialists: false }));
      if (userDataResponse.status && userDataResponse.user) {
        setSpecialistData(userDataResponse.user);
      }
    };
    makeAsync();
  }, [userData, token, latinFullname]);

  useEffect(() => {
    const makeAsync = async () => {
      if (
        !token ||
        !specialistData ||
        !specialistData._id ||
        !specialistData.specialistIds
      )
        return;
      const response = await SpecialistApi.getSpecialistFreeTime(
        token,
        specialistData._id,
        specialistData.specialistIds[0]!._id
      );

      if (response.status) {
        const allOverlaps: { start: Date; end: Date }[] =
          response.overlapDays.flatMap((overlap) =>
            overlap.overlaps.flatMap((dayOverlap) => dayOverlap.overlapTime)
          );

        setOverlapTime(allOverlaps);
      }
    };
    makeAsync();
  }, [specialistData]);

  const bookingHandler = async (
    sessionBookingData: { date: Date | string; duration: number }[]
  ) => {
    if (
      token &&
      userData &&
      userData._id &&
      specialistData &&
      specialistData._id &&
      specialistData.specialistIds &&
      sessionBookingData.length
    ) {
      const response = await SessionsApi.bookSession(
        {
          datesAndTime: sessionBookingData,
          specialistUserId: specialistData._id,
          userId: userData._id,
          projectId: projectId,
          specialistId: specialistData.specialistIds[0]._id,
        },
        token
      );
      if (!response.status && !response.notEnoughBalance) {
        return {
          status: response.status,
          notEnoughBalance: response.notEnoughBalance,
        };
      }
      if (!response.status && response.notEnoughBalance && response.message) {
        Notify(response.message);
        return {
          status: response.status,
          notEnoughBalance: response.notEnoughBalance,
        };
      }
      if (response.status && specialistData) {
        createChat(userData, specialistData?._id);
        /*    setIsLoader(false); */
        setBookingModalIsOpen(false);
        SuccesNotify(strings.bookingSucces);
        return {
          status: response.status,
          notEnoughBalance: response.notEnoughBalance,
        };
      }
    }
    return { status: false, notEnoughBalance: false };
  };

  if (actionLoaders.laodSpecialists || !specialistData) {
    return (
      <Skeleton
        variant="rectangular"
        width={"100%"}
        height={60}
        style={{ marginBottom: "3px" }}
        animation="wave"
      />
    );
  }

  return (
    <div className={s.container} style={{ backgroundColor: bgColor }}>
      <div className={s.mainSpecInfoBlock}>
        <NavigateToProfile userId={userId}>
          <CircleAvatar
            userId={userId}
            width={window.innerWidth <= 768 ? "40px" : "50px"}
            height={window.innerWidth <= 768 ? "40px" : "50px"}
          />{" "}
        </NavigateToProfile>
        <NavigateToProfile userId={userId}>
          <div className={s.mainSpecTitleInfo}>
            <span>
              {specialistData &&
                specialistData.name + " " + specialistData.surname}
            </span>
          </div>{" "}
        </NavigateToProfile>
      </div>
      <div className={s.specalizationTitleBlock}>
        <span>
          {specialistData && specialistData.specialistIds
            ? specialistData.specialistIds[0].mainSpecializations[0].labels.find(
                (el) => el.language === userData?.selectedLanguage
              )?.text
            : ""}
        </span>
      </div>
      <div className={s.languagesBlock}>
        {specialistData &&
          specialistData.preferredLanguages?.map((item, index) => (
            <span key={index}>
              {item +
                (index === specialistData.preferredLanguages!.length - 1
                  ? ""
                  : ", ")}
            </span>
          ))}
      </div>
      {sessionPrice && (
        <div className={s.prcieBlock}>
          <span>
            {sessionPrice.priceWithDiscount}
            <TokenIcon className={s.starIcon} />
          </span>
          {discount > 0 && <span>{sessionPrice.generalPrice}</span>}
        </div>
      )}
      <div className={s.bookingBlock}>
        <StandartButton
          action={() => setBookingModalIsOpen(true)}
          buttonTitle={strings.specCardBookingBtn}
          width="130px"
        />
      </div>
      <ModalWindow
        isOpen={bookingModalIsOpen}
        setIsOpen={setBookingModalIsOpen}
        width="fit-content"
      >
        <SelectSessionDate
          allSpecializations={specialistData.specialistIds!.map((item) => ({
            specialistId: item._id,
            price: item.price!,
            lable:
              item.mainSpecializations![0].labels.find(
                (el) => el.language === userData?.selectedLanguage
              )?.text ?? item.mainSpecializations[0].specialization,
          }))}
          showDateInputs={false}
          bookingHandler={bookingHandler}
          overlapTime={overlapTime}
          userId={specialistData ? specialistData._id : ""}
          fullname={specialistData?.name + " " + specialistData?.surname}
          price={sessionPrice ? Number(sessionPrice.generalPrice) * 100 : 0}
          projectId={projectId}
        />
      </ModalWindow>
    </div>
  );
};

export default ProgramSpecialistCard;
