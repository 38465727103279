import { Tooltip } from "@mui/material";
import { useEffect, useState } from "react";
import SessionsApi from "../../api/sessionApi";
import UsersApi from "../../api/usersApi";
import { ReactComponent as FavoriteIcon } from "../../assets/Profile/specialist/favourite.svg";
import { ReactComponent as AlredyWorlIcon } from "../../assets/Profile/specialist/work-before.svg";
import { ReactComponent as ToProfileIcon } from "../../assets/SpecialistCard/navigate-to-profile.svg";
import strings from "../../localization";
import NavigateToProfile from "../NavigateToProfile/NavigateToProfile";
import s from "./SpecialistCard.module.css";

type SpecialistCardProps = {
  userId: string;
  fullName: string;
  mainSpeciality: string[];
  languages: string[];
  location: string;
  isRecommendationCard?: boolean;
  avatarHeight?: string;
  avatarBlockBorderRadius?: string;
  mainInfoBorderRadius?: string;
  projectId?: string;
  briefDescription?: string;
};

const SpecialistCard = ({
  userId,
  fullName,
  mainSpeciality,
  languages,
  location,
  isRecommendationCard,
  avatarHeight,
  avatarBlockBorderRadius,
  mainInfoBorderRadius,
  projectId,
  briefDescription,
}: SpecialistCardProps) => {
  const token = localStorage.getItem("token");
  const [avatar, setAvatar] = useState("");
  const [totalInfo, setTotalInfo] = useState<{
    reviewTotal?: number;
    sessionsTotal?: number;
  }>({});
  const [isFavouriteSpec, setIsFavouriteSpec] = useState<boolean | undefined>();
  const [alreadyWork, setAlreadyWork] = useState(false);

  useEffect(() => {
    const makeAsync = async () => {
      if (!token || !userId) return;
      try {
        const [checkWorkResponse, favouriteResponse] = await Promise.all([
          UsersApi.checkIfUserWorkedWithSpecialist(token, userId),
          UsersApi.checkIfUserIsFavourite(token, userId),
        ]);
        if (checkWorkResponse.status) {
          setAlreadyWork(checkWorkResponse.worked);
        }
        if (favouriteResponse.status) {
          setIsFavouriteSpec(favouriteResponse.isFavourite);
        }
      } catch (error) {
        console.error(error);
      }
    };
    makeAsync();
  }, [userId, token]);

  const getSpecialistsSessionsAmount = async () => {
    if (!token || !userId) return;
    const response = await SessionsApi.getSpecialistsSessionsAmount(userId);
    if (response.status) {
      setTotalInfo((prev) => ({ ...prev, sessionsTotal: response.amount }));
    }
  };
  const getSpecialistsComments = async () => {
    if (!token || !userId) return;
    const commentsResponse = await SessionsApi.getSpecialistsComments(
      userId,
      1,
      1
    );

    setTotalInfo((prev) => ({
      ...prev,
      reviewTotal: commentsResponse.totalAmount,
    }));
  };

  useEffect(() => {
    console.log(projectId); //For navigation state to Profile
    const makeAsync = async () => {
      if (token && userId) {
        const responseAvatar = await UsersApi.getUserPublicAvatarAndName(
          userId
        );
        getSpecialistsComments();
        getSpecialistsSessionsAmount();
        if (responseAvatar.avatar) setAvatar(responseAvatar.avatar);
      }
    };
    makeAsync();
  }, [userId]);

  return (
    <div
      className={window.innerWidth > 600 ? s.container : s.mobileContainer}
      style={{ flexDirection: isRecommendationCard ? "row" : "column" }}
    >
      <NavigateToProfile
        userId={userId}
        width={isRecommendationCard ? "fit-content" : "100%"}
        height="100%"
      >
        <div className={s.avatarBlock}>
          {!avatar || avatar === "" ? (
            <div
              className={s.noAvatarBlock}
              style={{
                width: isRecommendationCard ? "200px" : "100%",
                height: isRecommendationCard ? "250px" : "auto",
                borderRadius: avatarBlockBorderRadius ?? "20px 20px 0 0",
              }}
            >
              {fullName.split(" ")[0][0]}
              {fullName.split(" ")[1][0]}
            </div>
          ) : (
            <img
              src={avatar}
              alt=""
              className={s.avatarBlur}
              height={avatarHeight ?? "auto"}
              width={isRecommendationCard ? "200px" : "100%"}
              style={{
                borderRadius: avatarBlockBorderRadius ?? "20px 20px 0 0",
              }}
            />
          )}
          <div
            className={s.blurActionBlock}
            style={{
              borderRadius: isRecommendationCard
                ? "0 0 0 20px"
                : "20px 20px 0 0",
            }}
          >
            <div className={s.buttonsBlock}>
              <div className={s.navigateToProfileBtn}>
                <ToProfileIcon className={s.profileIcon} />
                <span className={s.viewProfileText}>
                  {strings.viewProfileBtn}
                </span>
              </div>
            </div>
          </div>
        </div>
      </NavigateToProfile>
      <div
        className={
          window.innerWidth > 600 ? s.mainInfoBlock : s.mobileMainInfoBlock
        }
        style={{
          borderRadius: mainInfoBorderRadius ?? "0 0 20px 20px",
          height: isRecommendationCard ? "auto" : "inherit",
        }}
      >
        <div className={s.fullNameValue}>{fullName}</div>
        <div className={s.specializationValue}>{mainSpeciality.join(", ")}</div>
        {totalInfo.reviewTotal !== undefined &&
          totalInfo.sessionsTotal !== undefined && (
            <div className={s.totalBlock}>
              <div className={s.totalElement}>
                <span>{totalInfo.reviewTotal}</span>
                <span>{strings.reviewsSpecCArd}</span>
              </div>
              <div className={s.totalElement}>
                <span>{totalInfo.sessionsTotal}</span>
                <span>{strings.sessionSpecCard}</span>
              </div>
            </div>
          )}
        <div className={s.briefDescription}>{briefDescription}</div>
        <div style={{ marginBlockStart: "auto" }}>
          <div className={s.locationRatingBlock}>
            <span className={s.locationValue}>{location}</span>
            {/*  <span className={s.ratingValue}>
              {strings.ratingSpecProfile + " " + rating?.toFixed(1)}
            </span> */}
          </div>
          <div className={s.professionalInfoBlock}>
            <div>
              {languages.map((item, index) => (
                <span className={s.langValue} key={index}>
                  {item + " "}
                </span>
              ))}
            </div>
            <div className={s.iconsBlock}>
              {isFavouriteSpec && (
                <Tooltip arrow title={strings.inFavoiteTooltip}>
                  <FavoriteIcon className={s.favIcon} />
                </Tooltip>
              )}
              {alreadyWork && (
                <Tooltip arrow title={strings.alreadyWorked}>
                  <AlredyWorlIcon className={s.alreadyWorkIcon} />
                </Tooltip>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default SpecialistCard;
