import { CircularProgress, ThemeProvider } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import DialogChatAPI from "../../../../../api/dialogChatApi";
import SpecialistApi from "../../../../../api/specialistApi";
import { ReactComponent as ArrowIcon } from "../../../../../assets/Chat/arrow-right.svg";
import { ReactComponent as CloseIcon } from "../../../../../assets/HomePage/close.svg";
import StandartButton from "../../../../../components/StandartButton/StandartButton";
import { ContextProvider } from "../../../../../contextProvider";
import { useCustomTimer } from "../../../../../hooks/Timer";
import strings from "../../../../../localization";
import { RealTimeBooking } from "../../../../../types/TypeSession";
import { checkElapsedTime } from "../../../../../utils/general";
import { theme } from "../../../../../utils/theme";
import s from "./RealTimeSessionMessageCard.module.css";

interface Message {
  messageId: string;
  userId: string;
  text: string;
  sender: string;
  files: any[];
  date?: Date;
  realTimeBooking?: RealTimeBooking;
}

type MessageComponentProps = {
  message: Message;
  name?: string;
  surname?: string;
};

interface TimeLeft {
  hours: number;
  minutes: number;
  seconds: number;
}

const RealTimeSessionMessageCard = ({
  message,
  name,
  surname,
}: MessageComponentProps) => {
  const { userData, setUserData } = useContext(ContextProvider);
  const token = localStorage.getItem("token");
  const navigate = useNavigate();
  const [messageData, setMessageData] = useState(message);
  const [sessionAccepted, setSessionAccepted] = useState(
    messageData.realTimeBooking?.isAccepted
  );

  const [sessionExpired, setSessionExpired] = useState(
    messageData.realTimeBooking?.isExpired
  );
  const [actionLoader, setActionLoader] = useState(false);
  const [rejectModalVisible, setRejectModalVisible] = useState(false);
  const [rejectReasonValue, setRejectReasonValue] = useState("");
  const [cancelReason, setCancelReason] = useState(
    messageData.realTimeBooking?.cancelReason
  );

  const timeLimit: number = 15 * 60 * 1000; // 15 минут в миллисекундах

  const remainingTime: TimeLeft = useCustomTimer(
    //@ts-expect-error
    new Date(messageData.realTimeBooking?.creationDate!.replace("Z", "")),
    timeLimit
  );

  const acceptSession = async () => {
    if (!userData || !token || !messageData.realTimeBooking) return;
    setActionLoader(true);
    const response = await SpecialistApi.acceptRealTimeSessionRequest(
      token,
      userData._id,
      messageData.realTimeBooking._id
    );
    setActionLoader(false);
    if (response.status) {
      setSessionAccepted(true);
      if (!token || !userData || !userData.specialistIds) return;

      setUserData((prev) => ({
        ...prev!,
        specialistIds: prev!.specialistIds!.map((item) => ({
          ...item,
          receiveRealTimeOffers: false,
        })),
      }));
    }
  };

  const rejectSession = async (rejectReasonValue: string) => {
    if (!userData || !token || !messageData.realTimeBooking) return;
    setActionLoader(true);
    const response = await SpecialistApi.cancelRealTimeSessionRequest(
      token,
      userData._id,
      messageData.realTimeBooking._id,
      rejectReasonValue
    );

    setActionLoader(false);
    if (response.status) {
      setRejectModalVisible(false);
      setRejectReasonValue("");
      setSessionExpired(true);
      setCancelReason(rejectReasonValue);
    }
  };

  const joinSession = () => {
    if (!messageData.realTimeBooking?.bookedSession) return;
    navigate("/session-meet", {
      state: {
        sessionId: messageData.realTimeBooking?.bookedSession,
      },
    });
  };

  useEffect(() => {
    if (
      remainingTime.minutes !== 0 ||
      remainingTime.hours !== 0 ||
      remainingTime.seconds !== 0 ||
      messageData.realTimeBooking?.isExpired ||
      sessionExpired
    )
      return;
    setSessionExpired(true);
    const reason = strings.autoCancelSessionReason;
    setCancelReason(reason);
    rejectSession(reason);
  }, [remainingTime]);

  useEffect(() => {
    const getDialogMessage = async () => {
      if (
        !token ||
        (messageData.realTimeBooking && messageData.realTimeBooking.isExpired)
      )
        return;

      const response = await DialogChatAPI.getDialogMessageById(
        token,
        messageData.messageId
      );

      if (response.status && response.responseMessage) {
        setMessageData((prev) => ({
          ...prev,
          realTimeBooking: response.responseMessage?.realTimeBooking,
        }));
      }
    };

    const intervalId = setInterval(getDialogMessage, 5000);
    return () => clearInterval(intervalId);
  }, [token, message]);

  return (
    <div className={s.container}>
      {messageData.sender === "other" ? (
        <div className={s.messageForSpec}>
          <div className={s.requestHeadBlock}>
            <span className={s.requestTitle}>
              {strings.receivedRequesrSession}{" "}
              <span className={s.requestTitleFrom}>{name + " " + surname}</span>
            </span>
          </div>
          <div className={s.subjectBlock}>
            <span>{strings.subject} </span>
            <span>
              {messageData.realTimeBooking
                ? messageData.realTimeBooking.subject
                : ""}
            </span>
          </div>
          {actionLoader ? (
            <div className={s.loaderContainer}>
              <ThemeProvider theme={theme}>
                <CircularProgress size={30} color="primary" />
              </ThemeProvider>
            </div>
          ) : messageData.realTimeBooking &&
            !messageData.realTimeBooking.isAccepted &&
            !messageData.realTimeBooking.isExpired &&
            !sessionAccepted &&
            !sessionExpired ? (
            rejectModalVisible ? (
              <div className={s.rejectReasonBlock}>
                <span className={s.reasonIcon}>!</span>
                <input
                  type="text"
                  className={s.rejectInput}
                  placeholder={strings.reasonPlaceHodler}
                  value={rejectReasonValue}
                  onChange={(e) => setRejectReasonValue(e.target.value)}
                />
                {rejectReasonValue.length ? (
                  <ArrowIcon
                    className={s.arrowIcon}
                    onClick={() => rejectSession(rejectReasonValue)}
                  />
                ) : (
                  <CloseIcon
                    className={s.cancelRejectBtn}
                    onClick={() => setRejectModalVisible(false)}
                  />
                )}
              </div>
            ) : (
              <div className={s.actionBlock}>
                <div className={s.acceptBlock}>
                  <button className={s.acceptBtn} onClick={acceptSession}>
                    {strings.acceptBtn}
                  </button>
                  <button
                    className={s.rejectBtn}
                    onClick={() => setRejectModalVisible(true)}
                  >
                    {strings.rejectBtn}
                  </button>
                </div>
                {remainingTime.minutes === 0 && remainingTime.seconds === 0 ? (
                  <div className={s.emptySpan}></div>
                ) : (
                  <div className={s.timerBlock}>
                    <span className={s.timerElement}>
                      {remainingTime.minutes.toString().padStart(2, "0")}
                    </span>{" "}
                    :{" "}
                    <span className={s.timerElement}>
                      {remainingTime.seconds.toString().padStart(2, "0")}
                    </span>
                  </div>
                )}
              </div>
            )
          ) : (messageData.realTimeBooking &&
              messageData.realTimeBooking.isExpired &&
              !messageData.realTimeBooking.isAccepted) ||
            (messageData.realTimeBooking &&
              !sessionAccepted &&
              sessionExpired) ? (
            <div className={s.cancelTitleBlock}>
              <span className={s.cancelTitle}>{strings.canceledSession}</span>
              <div className={s.cancelReason}>
                <span>{strings.reasonTitle} </span>
                <span>
                  {" "}
                  {messageData.realTimeBooking?.cancelReason &&
                  messageData.realTimeBooking?.cancelReason.length &&
                  messageData.realTimeBooking?.labels.length
                    ? messageData.realTimeBooking?.labels.find(
                        (item) => item.language === userData?.selectedLanguage
                      )?.text
                    : cancelReason}
                </span>
              </div>
            </div>
          ) : (
            <div className={s.joinBlock}>
              <StandartButton
                buttonTitle={strings.joinBtn}
                action={joinSession}
                disabled={
                  !checkElapsedTime(
                    new Date(
                      //@ts-expect-error
                      messageData.realTimeBooking.creationDate.replace("Z", "")
                    ),
                    "Etc/GMT"
                  )
                }
                width="200px"
              />
            </div>
          )}
        </div>
      ) : (
        <div className={s.messageForClient}>
          {(messageData.realTimeBooking &&
            messageData.realTimeBooking.isAccepted) ||
          (messageData.realTimeBooking && sessionAccepted && sessionExpired) ? (
            <div className={s.clientSessionStatusBlock}>
              <span className={s.requestTitle}>
                {strings.specialistConfirmedRequest}
              </span>
              <div className={s.joinBlock}>
                <StandartButton
                  buttonTitle={strings.joinBtn}
                  action={joinSession}
                  disabled={
                    !checkElapsedTime(
                      new Date(
                        //@ts-expect-error
                        messageData.realTimeBooking.creationDate.replace(
                          "Z",
                          ""
                        )
                      ),
                      "Etc/GMT"
                    )
                  }
                  width="200px"
                />
              </div>
            </div>
          ) : messageData.realTimeBooking &&
            !messageData.realTimeBooking.isAccepted &&
            !messageData.realTimeBooking.isExpired &&
            !sessionExpired ? (
            <div className={s.clientSessionStatusBlock}>
              <div className={s.headClientTitleBlock}>
                <span className={s.emptySpan}></span>
                <span className={s.requestTitle}>
                  {strings.sessionRequest}
                </span>{" "}
                {remainingTime.minutes === 0 && remainingTime.seconds === 0 ? (
                  <span className={s.emptySpan}></span>
                ) : (
                  <div>
                    {remainingTime.minutes.toString().padStart(2, "0")} :{" "}
                    {remainingTime.seconds.toString().padStart(2, "0")}
                  </div>
                )}
              </div>
              <span className={s.clientSessionStatusTitle}>
                {strings.waitSpecResponse}
              </span>
            </div>
          ) : (
            <div className={s.clientSessionStatusBlock}>
              <span className={s.requestTitle}>{strings.sessionReques}</span>
              <div className={s.cancelTitleBlock}>
                <span className={s.cancelTitle}>{strings.canceledSession}</span>
                <div className={s.cancelReason}>
                  <span>{strings.reasonTitle} </span>
                  <span>
                    {messageData.realTimeBooking?.cancelReason &&
                    messageData.realTimeBooking?.cancelReason.length &&
                    messageData.realTimeBooking?.labels.length
                      ? messageData.realTimeBooking?.labels.find(
                          (item) => item.language === userData?.selectedLanguage
                        )?.text
                      : messageData.realTimeBooking?.cancelReason}
                  </span>
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default RealTimeSessionMessageCard;
