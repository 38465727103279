import { Tooltip } from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import Compressor from "compressorjs";
import dayjs, { Dayjs } from "dayjs";
import countries from "i18n-iso-countries";
import React, {
  ChangeEvent,
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from "react";
import ReactFlagsSelect from "react-flags-select";
import { allTimezones, useTimezoneSelect } from "react-timezone-select";
import NotificationsApi from "../../../../api/notifications";
import SpecialistApi from "../../../../api/specialistApi";
import UsersApi from "../../../../api/usersApi";
import { ReactComponent as DeleteIcon } from "../../../../assets/Notes/delete.svg";
import EditIcon from "../../../../assets/Profile/cloud-plus.svg";
import { ReactComponent as PlusIcon } from "../../../../assets/Profile/specialist/add.svg";
import { ReactComponent as ClockIcon } from "../../../../assets/Profile/specialist/clock.svg";
import { ReactComponent as ImportIcon } from "../../../../assets/Profile/specialist/import.svg";
import { ReactComponent as CircleInfoIcon } from "../../../../assets/Profile/specialist/info-circle.svg";
import { ReactComponent as VerifiedIcon } from "../../../../assets/Profile/specialist/verify.svg";
import AddCertificatesModal from "../../../../components/AddCertificatesModal/AddCertificatesModal";
import CircleAvatar from "../../../../components/CircleAvatar/CircleAvatar";
import CityPicker, {
  PlaceType,
} from "../../../../components/CitySelect/CitySelect";
import ConfirmationModal from "../../../../components/ConfirmationModal/ConfirmationModal";
import CropAvatarModal from "../../../../components/CropAvatarModal/CropAvatarModal";
import Input from "../../../../components/Input/Input";
import ModalWindow from "../../../../components/ModalWindow/ModalWindow";
import MuiSwitch from "../../../../components/MuiSwitch/MuiSwitch";
import MultipleSelect from "../../../../components/MultipleSelect/MultipleSelect";
import NewShedule from "../../../../components/Schedule/NewShedule";
import StandartButton from "../../../../components/StandartButton/StandartButton";
import {
  ISO639LangReversed,
  popularLanguages,
} from "../../../../constans/languagesList";
import { ContextProvider } from "../../../../contextProvider";
import strings from "../../../../localization";
import localizationJson from "../../../../localization.json";
import {
  EducationCertificatesResponse,
  FederationsCertificatesResponse,
  QualificationCertificatesResponse,
  SpecialistData,
} from "../../../../types/TypeSpecialist";
import { TypeUpdatedUserInfo, TypeUserData } from "../../../../types/TypeUsers";
import { formatEducationPeriod } from "../../../../utils/dateActions";
import { transformSchedule } from "../../../../utils/shedule";
import Notify, { SuccesNotify } from "../../../../utils/toaster";
import ChangeSpecialistTags from "./ChangeSpecialistTags/ChangeSpecialistTags";
import s from "./EditSpecialistProfile.module.css";
import LangTabs from "./LangTabs/LangTabs";

countries.registerLocale(require("i18n-iso-countries/langs/en.json"));

const labelStyle = "original";
const timezones = {
  ...allTimezones,
  "Europe/Berlin": "Frankfurt",
};

interface ServerData {
  [key: string]: { time: string[]; switch: boolean };
}

type MainSpecialiszationsData = {
  value: string;
  lable: string;
};
type ScheduleType = {
  dayValue: string;
  daytitle: string;
  isOpen: boolean;
  switch: boolean;
  workTime: { from: string; to: string }[];
};

type CertificatesData = {
  education: EducationCertificatesResponse[] | undefined;
  qualification: QualificationCertificatesResponse[] | undefined;
  federation: FederationsCertificatesResponse[] | undefined;
};

type EditProfileProps = {
  userUpdateData: TypeUpdatedUserInfo;
  profileUserData: TypeUserData | undefined | null;
  setUserUpdateData: Dispatch<SetStateAction<TypeUpdatedUserInfo>>;
  updateData: () => void;
  calendarIsConnected: boolean;
  specialistUpdateData: TypeUpdatedUserInfo;
  specialistData: SpecialistData | undefined;
  setSpecialistUpdateData: Dispatch<SetStateAction<TypeUpdatedUserInfo>>;
  setVideo: Dispatch<SetStateAction<File | null>>;
  daysState: ScheduleType[];
  setDaysState: Dispatch<SetStateAction<ScheduleType[]>>;
  video: File | null;
  avatarFile: File | null;
  setAvatarFile: Dispatch<SetStateAction<File | null>>;
  processServerData: (data: ServerData) => void;
};

export const EditSpecialistProfile = ({
  userUpdateData,
  profileUserData,
  setUserUpdateData,
  updateData,
  calendarIsConnected,
  specialistUpdateData,
  specialistData,
  setSpecialistUpdateData,
  setVideo,
  daysState,
  setDaysState,
  video,
  setAvatarFile,
  processServerData,
  avatarFile,
}: EditProfileProps) => {
  const token = localStorage.getItem("token");
  const _ = require("lodash");
  const { userData, setSelectLang, setUserData, selectLang } =
    useContext(ContextProvider);
  const { options } = useTimezoneSelect({
    labelStyle,
    timezones,
  });
  const [availableSiteLanguages, setAvailableSiteLanguages] = useState<
    { lable: string; value: string }[] | null
  >(null);
  const [selectedCity, setSelectedCity] = useState<PlaceType | null>(null);
  const [selectedCountry, setSelectedCountry] = useState<{
    code: string;
    title: string;
  }>({ code: "", title: "" });

  const [langages, setLangages] = useState<string | string[]>([]);
  const [checkedSpecializations, setCheckedSpecializations] = useState<
    MainSpecialiszationsData[]
  >([]);
  const [realTimeSessionsChecked, setRealTimeSessionsChecked] = useState<
    boolean | null
  >(null);
  const [videoTitle, setVideoTitle] = useState<string | null>(null);
  const [selectedScheduleDay, setSelectedScheduleDay] =
    useState<ScheduleType | null>(null);
  const [sheduleModalIsOpen, setSheduleModalIsOpen] = useState(false);
  const [settingsAvatarModal, setSettingsAvatarModal] = useState(false);
  const [professionContribution, setProfessionContribution] = useState<
    { text: string; language: string; selected: boolean }[] | undefined
  >();
  const [updateBriefDescriptions, setUpdateBriefDescriptions] = useState<
    { text: string; language: string; selected: boolean }[] | undefined
  >();
  const [updateAboutMyself, setUpdateAboutMyself] = useState<
    { text: string; language: string; selected: boolean }[] | undefined
  >();
  const [updateAboutTeraphy, setUpdateAboutTeraphy] = useState<
    { text: string; language: string; selected: boolean }[] | undefined
  >();
  const [qualificationModal, setQualificationModal] = useState(false);
  const [educationModal, setEducationModal] = useState(false);
  const [federationModal, setFederationModal] = useState(false);
  const [certificatesData, setCertificatesData] = useState<CertificatesData>({
    education: undefined,
    qualification: undefined,
    federation: undefined,
  });
  const [federationConfirmModal, setFederationConfirmModal] = useState(false);
  const [qualificationConfirmModal, setQualificationConfirmModal] =
    useState(false);
  const [educationConfirmModal, setEducationConfirmModal] = useState(false);
  const [certificateIdToRemove, setCertificateIdToRemove] = useState<
    string | undefined
  >();

  useEffect(() => {
    if (!profileUserData) return;
    const emptyBrifeDescriptions = profileUserData.preferredLanguages!.map(
      (item) => ({
        text: "",
        langage: ISO639LangReversed[item],
      })
    );
    const currentBriefDescription = emptyBrifeDescriptions.map(
      (item, index) => ({
        text: profileUserData.briefDescription
          ? profileUserData.briefDescription.find(
              (el) => el.language === item.langage
            )?.text ?? ""
          : "",
        language: item.langage,
        selected: index === 0,
      })
    );
    setUpdateBriefDescriptions(currentBriefDescription);
  }, [profileUserData]);
  useEffect(() => {
    if (!specialistData || !profileUserData) return;
    const emptyAboutMyself = profileUserData.preferredLanguages!.map(
      (item) => ({
        text: "",
        langage: ISO639LangReversed[item],
      })
    );
    const currentAboutMyself = emptyAboutMyself.map((item, index) => ({
      text: specialistData.aboutMyself
        ? specialistData.aboutMyself.find((el) => el.language === item.langage)
            ?.text ?? ""
        : "",
      language: item.langage,
      selected: index === 0,
    }));
    setUpdateAboutMyself(currentAboutMyself);

    const emptyAboutTeraphy = profileUserData.preferredLanguages!.map(
      (item) => ({
        text: "",
        langage: ISO639LangReversed[item],
      })
    );
    const currentAboutTeraphy = emptyAboutTeraphy.map((item, index) => ({
      text: specialistData.aboutTherapy
        ? specialistData.aboutTherapy.find((el) => el.language === item.langage)
            ?.text ?? ""
        : "",
      language: item.langage,
      selected: index === 0,
    }));
    setUpdateAboutTeraphy(currentAboutTeraphy);
    const emptyContributeToProfession = profileUserData.preferredLanguages!.map(
      (item) => ({
        text: "",
        langage: ISO639LangReversed[item],
      })
    );
    const currentContributeToProfession = emptyContributeToProfession.map(
      (item, index) => ({
        text: specialistData.contributeToProfession
          ? specialistData.contributeToProfession.find(
              (el) => el.language === item.langage
            )?.text ?? ""
          : "",
        language: item.langage,
        selected: index === 0,
      })
    );
    setProfessionContribution(currentContributeToProfession);
  }, [specialistData]);

  useEffect(() => {
    if (!updateBriefDescriptions) return;
    const brifDescriptionToUpdate = updateBriefDescriptions
      .filter((el) => el.text.length > 0)
      .map((item) => ({
        text: item.text,
        language: item.language,
      }));
    if (
      !_.isEqual(brifDescriptionToUpdate, profileUserData?.briefDescription)
    ) {
      setUserUpdateData((prev) => ({
        ...prev,
        briefDescription: brifDescriptionToUpdate,
      }));
    }
  }, [updateBriefDescriptions]);
  useEffect(() => {
    if (!professionContribution) return;
    const professionContributionToUpdate = professionContribution
      .filter((el) => el.text.length > 0)
      .map((item) => ({
        text: item.text,
        language: item.language,
      }));
    if (
      !_.isEqual(
        professionContributionToUpdate,
        specialistData?.contributeToProfession
      )
    ) {
      setSpecialistUpdateData((prev) => ({
        ...prev,
        contributeToProfession: professionContributionToUpdate,
      }));
    }
  }, [professionContribution]);
  useEffect(() => {
    if (!updateAboutMyself) return;
    const aboutMyselfToUpdate = updateAboutMyself
      .filter((el) => el.text.length > 0)
      .map((item) => ({
        text: item.text,
        language: item.language,
      }));
    if (!_.isEqual(aboutMyselfToUpdate, specialistData?.aboutMyself)) {
      setSpecialistUpdateData((prev) => ({
        ...prev,
        aboutMyself: aboutMyselfToUpdate,
      }));
    }
  }, [updateAboutMyself]);

  useEffect(() => {
    if (!updateAboutTeraphy) return;
    const aboutTeraphyToUpdate = updateAboutTeraphy
      .filter((el) => el.text.length > 0)
      .map((item) => ({
        text: item.text,
        language: item.language,
      }));
    if (!_.isEqual(aboutTeraphyToUpdate, specialistData?.aboutTherapy)) {
      setSpecialistUpdateData((prev) => ({
        ...prev,
        aboutTherapy: aboutTeraphyToUpdate,
      }));
    }
  }, [updateAboutTeraphy]);

  useEffect(() => {
    if (!selectedScheduleDay) return;
    setSheduleModalIsOpen(true);
  }, [selectedScheduleDay]);

  const onChangeSpecialistDataText = (
    value: any,
    objKey: keyof TypeUpdatedUserInfo
  ) => {
    setSpecialistUpdateData((prev) => ({
      ...prev,
      [objKey]: value ? value : " ",
    }));
  };

  const onChangeUserDataText = (
    value: any,
    objKey: keyof TypeUpdatedUserInfo,
    maxLength?: number
  ) => {
    let trimmedValue = value.trim();
    if (maxLength && trimmedValue.length > maxLength) {
      trimmedValue = trimmedValue.substring(0, maxLength);
    }

    setUserUpdateData((prev) => ({
      ...prev,
      [objKey]: trimmedValue,
    }));
  };
  const languagesList = popularLanguages.map((item) => ({
    value: item,
    lable: item,
  }));

  const languageNames: { [key: string]: string } = {
    en: "English",
    uk: "Українська",
  };

  const getEducationCertificates = async () => {
    if (!token || !specialistData) return;
    const response = await SpecialistApi.getEducationCertificates(
      token,
      specialistData._id,
      1,
      5
    );
    if (response.status && response.certificates) {
      setCertificatesData((prev) => ({
        ...prev,
        education: response.certificates,
      }));
    }
  };

  const getFederationCertificates = async () => {
    if (!token || !specialistData) return;
    const response = await SpecialistApi.getFederationCertificates(
      token,
      specialistData._id,
      1,
      5
    );

    if (response.status && response.certificates) {
      setCertificatesData((prev) => ({
        ...prev,
        federation: response.certificates,
      }));
    }
  };

  const getQualificationCertificates = async () => {
    if (!token || !specialistData) return;
    const response = await SpecialistApi.getQualificationCertificates(
      token,
      specialistData._id,
      1,
      5
    );
    if (response.status && response.certificates) {
      setCertificatesData((prev) => ({
        ...prev,
        qualification: response.certificates,
      }));
    }
  };
  useEffect(() => {
    getEducationCertificates();
    getFederationCertificates();
    getQualificationCertificates();
  }, [specialistData]);

  useEffect(() => {
    if (
      selectedCity?.terms[0].value &&
      selectedCity?.terms[selectedCity?.terms.length - 1].value
    )
      setUserUpdateData((prev) => ({
        ...prev,
        location: selectedCity?.terms[0].value + ", " + selectedCountry.title,
      }));
  }, [selectedCity]);
  useEffect(() => {
    setSelectedCity(null);
  }, [selectedCountry]);

  useEffect(() => {
    const languageArray: {
      lable: string;
      value: string;
    }[] = Object.keys(localizationJson).map((key) => ({
      lable: languageNames[key],
      value: key,
    }));

    setAvailableSiteLanguages(languageArray);
  }, [localizationJson]);

  useEffect(() => {
    if (specialistData && specialistData.introductionVideo) {
      const fileName = specialistData.introductionVideo.filename;
      setVideoTitle(fileName);
    }
    if (video) {
      setVideoTitle(video.name);
    }
  }, [video]);

  const handleSelectLang = (value: string | string[]) => {
    setLangages(value);
    setUserUpdateData((prev) => ({ ...prev, preferredLanguages: value }));
  };

  const compressFiles = (
    file: File,
    setCompressedFiles: React.Dispatch<React.SetStateAction<File | null>>
  ): void => {
    /*  setActionLoaders((prev) => ({ ...prev, pickAvatarLoader: true })); */
    new Compressor(file, {
      quality: 0.6,
      success(result) {
        const compressedFile = new File([result], file.name, {
          type: result.type,
          lastModified: Date.now(),
        });

        if (compressedFile) {
          setCompressedFiles(compressedFile);
          /*  setActionLoaders((prev) => ({ ...prev, pickAvatarLoader: false })); */
          setSettingsAvatarModal(true);
        }
      },
      error(err) {
        console.error(err.message);
        /*   setActionLoaders((prev) => ({ ...prev, pickAvatarLoader: false })); */
      },
    });
  };

  const handleAvatarChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target && event.target.files) {
      const file = event.target.files[0];
      if (file) {
        compressFiles(file, setAvatarFile);
      }
    }
  };

  useEffect(() => {
    if (profileUserData && profileUserData.preferredLanguages)
      setLangages(profileUserData.preferredLanguages);
  }, [profileUserData]);

  useEffect(() => {
    if (!userData || !userData.location) return;
    const country = userData.location.split(", ")[1];
    const userCountry = countries.getAlpha2Code(country, "en");
    setSelectedCountry({
      code: userCountry ? userCountry : "",
      title: country,
    });
  }, [userData]);

  const changeLangHandler = async (value: string) => {
    if (!token || !userData) return;

    const resposne = await UsersApi.updateUserData(token, {
      _id: userData._id,
      selectedLanguage: value,
    });

    if (resposne.status) {
      setUserData((prev) => {
        if (!prev) return prev;
        return {
          ...prev,
          selectedLanguage: value,
        };
      });
      setSelectLang(value);
    }
  };

  const connectGoogleCalendar = async () => {
    if (!token) return;
    const response = await NotificationsApi.generateCode(token);
    if (response.status && response.url) {
      window.open(response.url);
    }
  };

  const getSubSpecializations = async (mainSpec: string) => {
    if (token && userData) {
      const subSpecResponse = await SpecialistApi.getSubSpecializations(token, [
        mainSpec,
      ]);

      if (subSpecResponse.status && subSpecResponse.subSpecializations) {
        const subSpecData = subSpecResponse.subSpecializations.map((item) => ({
          value: item._id,
          lable: item.labels.find(
            (item) => item.language === userData?.selectedLanguage
          )?.text!,
        }));

        setCheckedSpecializations(subSpecData);
      }
    }
  };

  useEffect(() => {
    if (specialistData) {
      setRealTimeSessionsChecked(specialistData.receiveRealTimeOffers);
    }
    if (specialistData && specialistData.mainSpecializations) {
      getSubSpecializations(
        specialistUpdateData.mainSpecializations
          ? specialistUpdateData.mainSpecializations[0]
          : specialistData.mainSpecializations[0]._id
      );
    }
  }, [userData]);

  useEffect(() => {
    if (!specialistUpdateData || !specialistUpdateData?.mainSpecializations)
      return;
    getSubSpecializations(specialistUpdateData.mainSpecializations);
  }, [specialistUpdateData]);

  const handleVideoChange = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files && event.target.files[0];
    if (file) {
      const videoElement = document.createElement("video");
      const fileReader = new FileReader();

      fileReader.onload = () => {
        videoElement.src = fileReader.result as string;
        videoElement.onloadedmetadata = () => {
          const aspectRatio =
            videoElement.videoHeight / videoElement.videoWidth;
          if (aspectRatio < 1) {
            Notify(strings.onlyVerticalVideo);
            setVideo(null);
          } else {
            setVideo(file); // Устанавливаем видео, если оно подходит
          }
        };
      };

      fileReader.readAsDataURL(file);
    } else {
      setVideo(null);
    }
  };

  useEffect(() => {
    const scheduleData = transformSchedule(daysState);

    setSpecialistUpdateData((prev) => ({
      ...prev,

      receiveRealTimeOffers: realTimeSessionsChecked,
    }));
    if (!_.isEqual(scheduleData, profileUserData?.schedule)) {
      setUserUpdateData((prev) => ({
        ...prev,
        schedule: scheduleData,
      }));
    }
  }, [daysState, realTimeSessionsChecked]);

  const cancelSheduleChanges = () => {
    if (!userData || !userData.schedule || !selectedScheduleDay) return;
    setSheduleModalIsOpen(false);
    processServerData(userData.schedule);
  };

  const hasValidTimeRange = (
    ranges: {
      from: string;
      to: string;
    }[]
  ): boolean => {
    return ranges.some((range) => range.from !== "" && range.to !== "");
  };
  const removeFederationCertificate = async () => {
    if (!token || !specialistData || !certificateIdToRemove) return;
    const response = await SpecialistApi.removeFederationCertificate(
      token,
      certificateIdToRemove,
      specialistData._id
    );

    if (response.status) {
      setCertificatesData((prev) => ({
        ...prev,
        federation: prev.federation!.filter(
          (el) => el._id !== certificateIdToRemove
        ),
      }));
      SuccesNotify(strings.certificateHasBeenRemoved);
      setFederationConfirmModal(false);
    }
  };
  const removeEducationCertificate = async () => {
    if (!token || !specialistData || !certificateIdToRemove) return;

    const response = await SpecialistApi.removeEducationCertificate(
      token,
      certificateIdToRemove,
      specialistData._id
    );

    if (response.status) {
      setCertificatesData((prev) => ({
        ...prev,
        education: prev.education!.filter(
          (el) => el._id !== certificateIdToRemove
        ),
      }));
      SuccesNotify(strings.certificateHasBeenRemoved);
      setEducationConfirmModal(false);
    }
  };
  const removeQualificationCertificate = async () => {
    if (!token || !specialistData || !certificateIdToRemove) return;
    const response = await SpecialistApi.removeQualificationCertificate(
      token,
      certificateIdToRemove,
      specialistData._id
    );
    if (response.status) {
      setCertificatesData((prev) => ({
        ...prev,
        qualification: prev.qualification!.filter(
          (el) => el._id !== certificateIdToRemove
        ),
      }));
    }
    SuccesNotify(strings.certificateHasBeenRemoved);
    setQualificationConfirmModal(false);
  };

  return (
    <div className={s.container}>
      <div className={s.leftProfileBlock}>
        <div className={s.headerEditBlock}>
          <div
            className={
              specialistData?.isVerified
                ? s.verifiedMessage
                : s.unverifiedMessage
            }
          >
            {specialistData?.isVerified ? (
              <div className={s.verifiedStatusBlock}>
                <VerifiedIcon className={s.verifiedStatusIcon} />{" "}
                {strings.verifiedMessage}
              </div>
            ) : (
              <div className={s.verifiedStatusBlock}>
                <CircleInfoIcon className={s.verifiedStatusIcon} />{" "}
                {strings.unVerifiedmessage}
              </div>
            )}
          </div>
          <StandartButton
            buttonTitle={strings.profileSaveBtn}
            action={updateData}
            disabled={
              specialistUpdateData.subSpecializations &&
              !specialistUpdateData.subSpecializations.length
                ? true
                : false
            }
            tooltipTitle={strings.selectSubSpecTooltip}
          />
          {/* <button className={s.saveProfileButton} onClick={updateData}>
            <span>{strings.profileSaveBtn}</span>
          </button> */}
        </div>
        <div className={s.mainEditBlock}>
          <div className={s.avatarBlock}>
            <div style={{ position: "relative" }}>
              <div className={s.avatarImageContainer}>
                {avatarFile ? (
                  <img
                    src={URL.createObjectURL(avatarFile)}
                    alt=""
                    className={s.avatarImg}
                  />
                ) : (
                  <CircleAvatar
                    userId={
                      profileUserData
                        ? profileUserData._id
                        : userData
                        ? userData._id
                        : ""
                    }
                    width="110px"
                    height="110px"
                    fontSize="42px"
                  />
                )}
              </div>

              <label>
                <span className={s.updateAvatarBtn} role="button">
                  <img src={EditIcon} alt="" />
                  <span>{strings.replace}</span>
                </span>
                <input
                  style={{ display: "none" }}
                  type="file"
                  accept="image/*"
                  onChange={(e) => handleAvatarChange(e)}
                />
              </label>
            </div>
            <div className={s.switchBlock}>
              <MuiSwitch
                lable={strings.profilerealTimeSessionSwitch}
                lablePlacement="top"
                checked={
                  realTimeSessionsChecked !== null
                    ? realTimeSessionsChecked
                    : false
                }
                setChecked={setRealTimeSessionsChecked}
              />
            </div>
          </div>
          <div className={s.personInfoContainer}>
            <div className={`${s.personInfoBlock} ${s.editPersonInfoBlock}`}>
              <span className={s.infoDescription}>
                {strings.generalFullNameDescription}
              </span>
              <div
                className={`${s.personInfoLineBlock} ${s.editPersonInfoLineBlock}`}
              >
                <div
                  className={`${s.personInfoElement} ${s.editPersonInfoElement}`}
                >
                  <span className={s.infoLable}>
                    {strings.firstNameProfileLable}
                  </span>

                  <Input
                    inputValue={
                      userUpdateData.name
                        ? userUpdateData.name
                        : profileUserData?.name
                    }
                    onChangeInput={(value) =>
                      onChangeUserDataText(value, "name", 32)
                    }
                    isVisible
                    required
                  />
                </div>
                <div
                  className={`${s.personInfoElement} ${s.editPersonInfoElement}`}
                >
                  <span className={s.infoLable}>
                    {strings.lastNameProfileLable}
                  </span>

                  <Input
                    inputValue={
                      userUpdateData.surname
                        ? userUpdateData.surname
                        : profileUserData?.surname
                    }
                    onChangeInput={(value) =>
                      onChangeUserDataText(value, "surname", 32)
                    }
                    isVisible
                    required
                  />
                </div>
              </div>
            </div>
            <div className={`${s.personInfoBlock} ${s.editPersonInfoBlock}`}>
              <span className={s.infoDescription}>
                {strings.latinFullNameDescription}
              </span>
              <div
                className={`${s.personInfoLineBlock} ${s.editPersonInfoLineBlock}`}
              >
                <div
                  className={`${s.personInfoElement} ${s.editPersonInfoElement}`}
                >
                  <span className={s.infoLable}>
                    {strings.firstNameProfileLable}
                  </span>

                  <Input
                    inputValue={
                      userUpdateData.latinName
                        ? userUpdateData.latinName
                        : profileUserData?.latinName
                    }
                    onChangeInput={(value) => {
                      const regex = /^[A-Za-z]*$/;
                      if (regex.test(value) || value === "") {
                        onChangeUserDataText(value, "latinName", 32);
                      }
                    }}
                    isVisible
                    required
                  />
                </div>
                <div
                  className={`${s.personInfoElement} ${s.editPersonInfoElement}`}
                >
                  <span className={s.infoLable}>
                    {strings.lastNameProfileLable}
                  </span>

                  <Input
                    inputValue={
                      userUpdateData.latinSurname
                        ? userUpdateData.latinSurname
                        : profileUserData?.latinSurname
                    }
                    onChangeInput={(value) => {
                      const regex = /^[A-Za-z]*$/;
                      if (regex.test(value) || value === "") {
                        onChangeUserDataText(value, "latinSurname", 32);
                      }
                    }}
                    isVisible
                    required
                  />
                </div>
              </div>
            </div>
            <div className={`${s.personInfoBlock} ${s.editPersonInfoBlock}`}>
              <div
                className={`${s.personInfoLineBlock} ${s.editPersonInfoLineBlock}`}
              >
                <div
                  className={`${s.personInfoElement} ${s.editPersonInfoElement}`}
                >
                  <span className={s.infoLable}>
                    {strings.fromCountryProfileLable}
                  </span>

                  <div className={s.countryBlock}>
                    <ReactFlagsSelect
                      blacklistCountries
                      countries={["RU"]}
                      className={s.countrySelect}
                      selected={selectedCountry.code}
                      onSelect={(code) =>
                        setSelectedCountry({
                          code: code,
                          title: countries.getName(code, "en") as string,
                        })
                      }
                      searchable
                      placeholder={
                        selectedCountry.code.length ? "" : "Select country"
                      }
                    />
                  </div>
                </div>

                <div
                  className={`${s.personInfoElement} ${s.editPersonInfoElement}`}
                >
                  <span className={s.infoLable}>
                    {strings.fromCityProfileLable}
                  </span>
                  <div className={s.cityPickerBlock}>
                    <CityPicker
                      selectedCity={selectedCity}
                      selectedCountry={selectedCountry.code}
                      onCitySelect={setSelectedCity}
                      defaultValue={userData?.location}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className={`${s.personInfoBlock} ${s.editPersonInfoBlock}`}>
              <div
                className={`${s.personInfoLineBlock} ${s.editPersonInfoLineBlock}`}
              >
                <div
                  className={`${s.personInfoElement} ${s.editPersonInfoElement}`}
                >
                  <span className={s.infoLable}>
                    {strings.dateBirthProfileLable}
                  </span>

                  <div className={s.dateOfBirthBlock}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        className={s.datePicker}
                        value={
                          userUpdateData.dateOfBirth
                            ? dayjs(userUpdateData.dateOfBirth)
                            : dayjs(profileUserData?.dateOfBirth)
                        }
                        onChange={(value: Dayjs | null) =>
                          setUserUpdateData((prev) => ({
                            ...prev,
                            dateOfBirth: value ? value.toDate() : null,
                          }))
                        }
                      />
                    </LocalizationProvider>
                    <div></div>
                  </div>
                </div>
                <div
                  className={`${s.personInfoElement} ${s.editPersonInfoElement}`}
                >
                  <div>
                    <span className={s.infoLable}>
                      {strings.langProfileLable}
                    </span>

                    <MultipleSelect
                      data={languagesList}
                      setValue={handleSelectLang}
                      value={langages}
                      multiplie={true}
                      padding="10px 20px"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {professionContribution && (
          <div className={s.aboutWriteBlock}>
            <div className={s.headLableBlock}>
              <div className={s.headLableText}>
                <h3>{strings.professionContribution}</h3>{" "}
                <span>
                  {`(${
                    professionContribution.find((el) => el.selected)?.text!
                      .length
                  } / 250)`}
                </span>
              </div>
              {professionContribution.length > 1 && (
                <LangTabs
                  languagesData={professionContribution.map((item) => ({
                    language: item.language,
                    selected: item.selected,
                  }))}
                  onClickFunc={(lang) =>
                    setProfessionContribution(
                      professionContribution.map((element) => {
                        if (element.language === lang) {
                          return {
                            ...element,
                            selected: true,
                          };
                        } else {
                          return {
                            ...element,
                            selected: false,
                          };
                        }
                      })
                    )
                  }
                />
              )}
            </div>
            <textarea
              name=""
              id=""
              cols={30}
              rows={10}
              value={professionContribution.find((el) => el.selected)?.text!}
              className={s.textAreaNewNote}
              onChange={(event) => {
                if (event.target.value.length > 250) return;
                setProfessionContribution((prev) => {
                  return prev
                    ? prev.map((item) => {
                        if (item.selected) {
                          return {
                            text: event.target.value,
                            language: item.language,
                            selected: true,
                          };
                        } else return item;
                      })
                    : undefined;
                });
              }}
            ></textarea>
          </div>
        )}
        {updateBriefDescriptions && (
          <div className={s.aboutWriteBlock}>
            <div className={s.headLableBlock}>
              <div className={s.headLableText}>
                <h3>{strings.brief}</h3>
                <span>
                  {`(${
                    updateBriefDescriptions.find((el) => el.selected)?.text!
                      .length
                  } / 250)`}
                </span>
              </div>

              {updateBriefDescriptions.length > 1 && (
                <LangTabs
                  languagesData={updateBriefDescriptions.map((item) => ({
                    language: item.language,
                    selected: item.selected,
                  }))}
                  onClickFunc={(lang) =>
                    setUpdateBriefDescriptions(
                      updateBriefDescriptions.map((element) => {
                        if (element.language === lang) {
                          return {
                            ...element,
                            selected: true,
                          };
                        } else {
                          return {
                            ...element,
                            selected: false,
                          };
                        }
                      })
                    )
                  }
                />
              )}
            </div>
            <textarea
              name=""
              id=""
              cols={30}
              rows={10}
              value={updateBriefDescriptions.find((el) => el.selected)?.text!}
              className={s.textAreaNewNote}
              onChange={(event) => {
                if (event.target.value.length > 250) return;
                setUpdateBriefDescriptions((prev) => {
                  return prev
                    ? prev.map((item) => {
                        if (item.selected) {
                          return {
                            text: event.target.value,
                            language: item.language,
                            selected: true,
                          };
                        } else return item;
                      })
                    : undefined;
                });
              }}
            ></textarea>
          </div>
        )}
        {updateAboutMyself && (
          <div className={s.aboutWriteBlock}>
            <div className={s.headLableBlock}>
              <div className={s.headLableText}>
                <h3>{strings.aboutMeSpecProfile}</h3>
                <span>
                  {`(${
                    updateAboutMyself.find((el) => el.selected)?.text!.length
                  } / 1500)`}
                </span>
              </div>

              {updateAboutMyself.length > 1 && (
                <LangTabs
                  languagesData={updateAboutMyself.map((item) => ({
                    language: item.language,
                    selected: item.selected,
                  }))}
                  onClickFunc={(lang) =>
                    setUpdateAboutMyself(
                      updateAboutMyself.map((element) => {
                        if (element.language === lang) {
                          return {
                            ...element,
                            selected: true,
                          };
                        } else {
                          return {
                            ...element,
                            selected: false,
                          };
                        }
                      })
                    )
                  }
                />
              )}
            </div>
            <textarea
              name=""
              id=""
              cols={30}
              rows={10}
              value={updateAboutMyself.find((el) => el.selected)?.text!}
              className={s.textAreaNewNote}
              onChange={(event) => {
                if (event.target.value.length > 1500) return;
                setUpdateAboutMyself((prev) => {
                  return prev
                    ? prev.map((item) => {
                        if (item.selected) {
                          return {
                            text: event.target.value,
                            language: item.language,
                            selected: true,
                          };
                        } else return item;
                      })
                    : undefined;
                });
              }}
            ></textarea>
          </div>
        )}
        {updateAboutTeraphy && (
          <div className={s.aboutWriteBlock}>
            <div className={s.headLableBlock}>
              <div className={s.headLableText}>
                <h3>{strings.howSessionGoesSpecProfile}</h3>
                <span>
                  {`(${
                    updateAboutTeraphy.find((el) => el.selected)?.text!.length
                  } / 1500)`}
                </span>
              </div>
              {updateAboutTeraphy.length > 1 && (
                <LangTabs
                  languagesData={updateAboutTeraphy.map((item) => ({
                    language: item.language,
                    selected: item.selected,
                  }))}
                  onClickFunc={(lang) =>
                    setUpdateAboutTeraphy(
                      updateAboutTeraphy.map((element) => {
                        if (element.language === lang) {
                          return {
                            ...element,
                            selected: true,
                          };
                        } else {
                          return {
                            ...element,
                            selected: false,
                          };
                        }
                      })
                    )
                  }
                />
              )}
            </div>
            <textarea
              name=""
              id=""
              cols={30}
              rows={10}
              value={updateAboutTeraphy.find((el) => el.selected)?.text!}
              className={s.textAreaNewNote}
              onChange={(event) => {
                if (event.target.value.length > 1500) return;
                setUpdateAboutTeraphy((prev) => {
                  return prev
                    ? prev.map((item) => {
                        if (item.selected) {
                          return {
                            text: event.target.value,
                            language: item.language,
                            selected: true,
                          };
                        } else return item;
                      })
                    : undefined;
                });
              }}
            ></textarea>
          </div>
        )}
        <div className={s.certificatesBlock}>
          <div className={s.myEducationBlock}>
            <div className={s.newCertificateBlock}>
              <div className={s.textCertificatesBlock}>
                <h3>{strings.myEducationLable}</h3>
                {/*  <span className={s.certificateDescription}>
                  {strings.certificateDescription}
                </span> */}
              </div>
              <div
                className={s.addCertificateBtn}
                onClick={() => setEducationModal(true)}
              >
                <PlusIcon className={s.plusIcon} />
                <span>{strings.addEducation}</span>
              </div>
            </div>
            <div className={s.certificatesListBlock}>
              {certificatesData.education &&
                certificatesData.education.map((item, index) => (
                  <div key={index} className={s.certificateElement}>
                    <div>
                      <span className={s.certificateNameTitle}>
                        {item.education.text},{" "}
                        <span className={s.secondTitle}>
                          {item.description}
                        </span>
                      </span>
                      <span className={s.secondTitle}>
                        {formatEducationPeriod(
                          new Date(item.membershipStart),
                          new Date(item.membershipEnd),
                          userData?.selectedLanguage!
                        )}
                      </span>
                    </div>
                    <div
                      onClick={() => {
                        setCertificateIdToRemove(item._id);
                        setEducationConfirmModal(true);
                      }}
                    >
                      <DeleteIcon className={s.deletIcon} />
                    </div>
                  </div>
                ))}
            </div>
            <ConfirmationModal
              confirmTitle={strings.confirmRemoveCertificate}
              confirmFunc={removeEducationCertificate}
              modalVisible={educationConfirmModal}
              setModalVisible={setEducationConfirmModal}
            />
          </div>
          <div className={s.myQualificationBlock}>
            <div className={s.newCertificateBlock}>
              <div className={s.textCertificatesBlock}>
                <h3>{strings.qualificationLable}</h3>
                {/*  <span className={s.certificateDescription}>
                  {strings.certificateDescription}
                </span> */}
              </div>

              <div
                className={s.addCertificateBtn}
                onClick={() => setQualificationModal(true)}
              >
                <PlusIcon className={s.plusIcon} />
                <span>{strings.addQualification}</span>
              </div>
            </div>
            <div className={s.certificatesListBlock}>
              {certificatesData.qualification &&
                certificatesData.qualification.map((item, index) => (
                  <div key={index} className={s.certificateElement}>
                    <div className={s.leftCertificateBlock}>
                      <img
                        src={item.qualification.badge.link}
                        alt=""
                        className={s.badgeIcon}
                      />
                      <div>
                        <span className={s.certificateNameTitle}>
                          {item.qualification.text}
                        </span>
                        <span className={s.secondTitle}>
                          {formatEducationPeriod(
                            new Date(item.membershipStart),
                            new Date(item.membershipEnd),
                            userData?.selectedLanguage!
                          )}
                        </span>
                      </div>
                    </div>
                    <div
                      onClick={() => {
                        setCertificateIdToRemove(item._id);
                        setQualificationConfirmModal(true);
                      }}
                    >
                      <DeleteIcon className={s.deletIcon} />
                    </div>
                  </div>
                ))}
            </div>
            <ConfirmationModal
              confirmTitle={strings.confirmRemoveCertificate}
              confirmFunc={removeQualificationCertificate}
              modalVisible={qualificationConfirmModal}
              setModalVisible={setQualificationConfirmModal}
            />
          </div>
          <div className={s.federationsBlock}>
            <div className={s.newCertificateBlock}>
              <div className={s.textCertificatesBlock}>
                <h3>{strings.federationsLable}</h3>
                {/*  <span className={s.certificateDescription}>
                  {strings.certificateDescription}
                </span> */}
              </div>

              <div
                className={s.addCertificateBtn}
                onClick={() => setFederationModal(true)}
              >
                <PlusIcon className={s.plusIcon} />
                <span>{strings.addFederation}</span>
              </div>
            </div>
            <div className={s.certificatesListBlock}>
              {certificatesData.federation &&
                certificatesData.federation.map((item, index) => (
                  <div key={index} className={s.certificateElement}>
                    <div className={s.leftCertificateBlock}>
                      <img
                        src={item.federation.badge.link}
                        alt=""
                        className={s.badgeIcon}
                      />
                      <div>
                        <span className={s.certificateNameTitle}>
                          {item.federation.text}
                        </span>
                        <span className={s.secondTitle}>
                          {formatEducationPeriod(
                            new Date(item.membershipStart),
                            new Date(item.membershipEnd),
                            userData?.selectedLanguage!
                          )}
                        </span>
                      </div>
                    </div>
                    <div
                      onClick={() => {
                        setCertificateIdToRemove(item._id);
                        setFederationConfirmModal(true);
                      }}
                    >
                      <DeleteIcon className={s.deletIcon} />
                    </div>
                  </div>
                ))}
              <ConfirmationModal
                confirmTitle={strings.confirmRemoveCertificate}
                confirmFunc={removeFederationCertificate}
                modalVisible={federationConfirmModal}
                setModalVisible={setFederationConfirmModal}
              />
            </div>
          </div>
        </div>
        {specialistData?.mainSpecializations[0].specialization.toLowerCase() ===
          "coach" && (
          <div className={s.tagsBlock}>
            <h3>{strings.tagsSpecProfile}</h3>
            <p className={s.blockDescriptionTitle}>{strings.selectTopics}</p>
            <ChangeSpecialistTags
              setSpecialistUpdateData={setSpecialistUpdateData}
              specialistData={specialistData}
            />
          </div>
        )}
      </div>
      <div className={s.rightProfileBlock}>
        <div className={s.professionalBlock}>
          <div className={s.inputBlock}>
            <span>{strings.presSession}</span>
            <Input
              inputValue={
                !specialistUpdateData.price || specialistUpdateData.price === ""
                  ? specialistData?.price
                  : specialistUpdateData.price
              }
              isVisible
              required
              onChangeInput={(value) =>
                onChangeSpecialistDataText(value, "price")
              }
            />
          </div>
          <div className={s.inputBlock}>
            <span>{strings.specProfileYearsExp}</span>
            <Input
              inputValue={
                !specialistUpdateData.yearsOfExperience ||
                specialistUpdateData.yearsOfExperience === ""
                  ? specialistData?.yearsOfExperience
                  : specialistUpdateData.yearsOfExperience
              }
              isVisible
              required
              onChangeInput={(value) =>
                onChangeSpecialistDataText(value, "yearsOfExperience")
              }
            />
          </div>
          <div className={s.inputBlock}>
            <span>{strings.specProfilePracticeHours}</span>
            <Input
              inputValue={
                !specialistUpdateData.practiceHours ||
                specialistUpdateData.practiceHours === ""
                  ? specialistData?.practiceHours
                  : specialistUpdateData.practiceHours
              }
              isVisible
              required
              onChangeInput={(value) =>
                onChangeSpecialistDataText(value, "practiceHours")
              }
            />
          </div>
          <div className={s.qualificationBlock}>
            <h2>{strings.qualification}</h2>
            <div className={s.multiSelectBlock}>
              <span>{strings.filterSubSpec}</span>
              <MultipleSelect
                multiplie={true}
                data={checkedSpecializations}
                setValue={(value) =>
                  onChangeSpecialistDataText(value, "subSpecializations")
                }
                value={
                  specialistUpdateData.subSpecializations
                    ? specialistUpdateData.subSpecializations
                    : specialistData!.subSpecializations!.map(
                        (item) => item._id
                      )
                }
              />
            </div>
          </div>
        </div>
        <div className={s.videoBlock}>
          <h2>{strings.videoTitle}</h2>
          <label>
            <div className={s.editVideoIcon} role="button">
              <ImportIcon className={s.importIcon} />
              <span>{videoTitle ?? "MP4"}</span>
            </div>
            <input
              style={{ display: "none" }}
              type="file"
              accept="video/*"
              onChange={(e) => handleVideoChange(e)}
            />
          </label>
        </div>
        <div className={s.settingsBlock}>
          {availableSiteLanguages && (
            <div className={s.selectLangBlock}>
              <span className={s.infoLable}>{strings.languageTitle}</span>
              <MultipleSelect
                data={availableSiteLanguages}
                multiplie={false}
                setValue={(value) => {
                  changeLangHandler(value as string);
                }}
                value={selectLang ? selectLang : ""}
                width="100%"
              />
            </div>
          )}
          <div className={s.timeZoneSelectBlock}>
            <span className={s.infoLable}>{strings.timezoneProfileLable}</span>

            <MultipleSelect
              data={options.map((option) => ({
                value: option.value,
                lable: option.label,
              }))}
              multiplie={false}
              setValue={(value) => onChangeUserDataText(value, "timezone")}
              value={
                userUpdateData.timezone
                  ? userUpdateData.timezone
                  : profileUserData?.timezone
              }
              padding="10px 20px"
            />
          </div>
          <div className={s.integrationsBlock}>
            <div className={s.infoTitleBlock}>
              <span>{strings.intagrationProfile}</span>
            </div>

            <div className={s.integrationsElement}>
              <p className={s.infoLable}>{strings.connectGoogleProfile}</p>
              <Tooltip
                title={calendarIsConnected ? strings.connectGoogleTooltip : ""}
                placement="right"
                arrow
              >
                <div
                  style={{
                    width: "fit-content",
                    marginTop: "10px",
                  }}
                >
                  <StandartButton
                    buttonTitle={strings.connectProfileBtn}
                    action={connectGoogleCalendar}
                    width="120px"
                    disabled={calendarIsConnected}
                  />
                </div>
              </Tooltip>
            </div>
          </div>
        </div>
        <div className={s.sheduleBlock}>
          <h2>{strings.timeToBookTitle}</h2>
          <div>
            {daysState && daysState.length
              ? daysState.map((item, index) => (
                  <div className={s.scheduleElement} key={index}>
                    <div
                      className={s.sheduleClockBlock}
                      role="button"
                      onClick={() => {
                        setSelectedScheduleDay(item);
                        const updatedSchedule = daysState.map((day) =>
                          day.dayValue === item.dayValue
                            ? { ...day, isOpen: !day.isOpen }
                            : { ...day, isOpen: false }
                        );
                        setDaysState(updatedSchedule);
                      }}
                    >
                      <ClockIcon />
                      <div className={s.dayTimeBlock}>
                        <span>{item.daytitle}</span>
                        <span>
                          {!hasValidTimeRange(item.workTime) || !item.switch ? (
                            strings.dayOffShedulTitle
                          ) : (
                            <span className={s.workingDayTitleBlock}>
                              {strings.workingDay}
                              <Tooltip
                                title={
                                  <div>
                                    {item.workTime.map((time, index) => (
                                      <div key={index}>
                                        {time.from} - {time.to}
                                      </div>
                                    ))}
                                  </div>
                                }
                                arrow
                              >
                                <span className={s.sheduleTooltip}>!</span>
                              </Tooltip>
                            </span>
                          )}
                        </span>
                      </div>
                    </div>
                    <div className={s.sheduleSwitchBlock}>
                      <MuiSwitch
                        lable=""
                        checked={item.switch}
                        setChecked={(value) => {
                          const updatedSchedule = daysState.map((day) =>
                            day.dayValue === item.dayValue
                              ? { ...day, switch: value }
                              : day
                          );
                          setDaysState(updatedSchedule);
                        }}
                      />
                    </div>
                  </div>
                ))
              : null}
          </div>
          {selectedScheduleDay && (
            <ModalWindow
              isOpen={sheduleModalIsOpen}
              setIsOpen={setSheduleModalIsOpen}
              width="500px"
              padding="0"
              bgColor="transparent"
            >
              <div>
                <div className={s.sheduleSettingsModalBlock}>
                  <NewShedule
                    allDaysState={daysState}
                    dayState={selectedScheduleDay}
                    setDaysState={setDaysState}
                  />
                </div>
                <div className={s.sheduleSettingsModalBtnBlock}>
                  <button
                    onClick={cancelSheduleChanges}
                    className={s.cancelShedulBtn}
                  >
                    {strings.cancelShedulModal}
                  </button>
                  <StandartButton
                    buttonTitle={strings.saveSheduleModal}
                    action={() => setSheduleModalIsOpen(false)}
                    width="50%"
                  />
                </div>
              </div>
            </ModalWindow>
          )}
        </div>
      </div>
      {avatarFile && (
        <CropAvatarModal
          avatar={avatarFile}
          modalVisible={settingsAvatarModal}
          setAvatar={setAvatarFile}
          setModalVisible={setSettingsAvatarModal}
        />
      )}
      <AddCertificatesModal
        certificateType="education"
        modalOpen={educationModal}
        setModalOpen={setEducationModal}
        specialization={specialistData?.mainSpecializations[0]._id!}
        specialistId={specialistData?._id!}
        modalTitle={strings.addEducation}
        setNewCertificate={setCertificatesData}
      />
      <AddCertificatesModal
        certificateType="federation"
        modalOpen={federationModal}
        setModalOpen={setFederationModal}
        specialization={specialistData?.mainSpecializations[0]._id!}
        specialistId={specialistData?._id!}
        modalTitle={strings.addFederation}
        setNewCertificate={setCertificatesData}
      />
      <AddCertificatesModal
        certificateType="qualification"
        modalOpen={qualificationModal}
        setModalOpen={setQualificationModal}
        specialization={specialistData?.mainSpecializations[0]._id!}
        specialistId={specialistData?._id!}
        modalTitle={strings.addQualification}
        setNewCertificate={setCertificatesData}
      />
    </div>
  );
};
