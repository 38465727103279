import ky from "ky";
import { network } from "../config";
import TypeDefaultResponse from "../types/TypeDefaultResponse";

import { TypeGoogleEvent } from "../types/General";
import { Sessions, Specializations } from "../types/TypeSession";
import {
  Badge,
  Certificates,
  Education,
  EducationCertificatesResponse,
  Federation,
  FederationsCertificatesResponse,
  MainSpecializations,
  OverLaps,
  ParamsAvailable,
  Qualification,
  QualificationCertificatesResponse,
  RealTimeBooking,
  SpecialistData,
  SpecialistFreeTimeType,
  SpecialistTag,
  SpecialistTagsDirections,
  SubSpecializations,
  TypeSpecialistFilter,
} from "../types/TypeSpecialist";
import { TypeUpdatedUserInfo, TypeUserData } from "../types/TypeUsers";

const { specialists } = network;

const baseURL = process.env.REACT_APP_HTTP_CONNECTION_STRING + "specialists/";

const getAuthHeader = (token: string) => {
  return { Authorization: `Bearer ${token}` };
};

export default class SpecialistApi {
  static async update(
    updateData: TypeUpdatedUserInfo,
    token: string
  ): Promise<
    TypeDefaultResponse & {
      specialistData: SpecialistData;
    }
  > {
    return await ky
      .post(`${baseURL}${specialists.update}/${updateData._id}`, {
        headers: getAuthHeader(token),
        json: { updateData },
      })
      .then((res) => res.json());
  }

  static async uploadIntroVideo(
    specialistId: string,
    formData: FormData,
    token: string
  ): Promise<TypeDefaultResponse> {
    return await ky
      .post(`${baseURL}${specialists.uploadIntroVideo}/${specialistId}`, {
        body: formData,
        headers: getAuthHeader(token),
        timeout: 30000,
      })
      .then((res) => res.json());
  }

  static async uploadCertificates(
    specialistId: string,
    formData: FormData,
    token: string
  ): Promise<TypeDefaultResponse> {
    return await ky
      .post(
        `${baseURL}${specialists.acceptRealTimeSessionRequest}/${specialistId}`,
        {
          body: formData,
          headers: getAuthHeader(token),
          timeout: 300000,
        }
      )
      .then((res) => res.json());
  }

  static async getRecommendedSpecialists(
    userId: string,
    token: string
  ): Promise<
    TypeDefaultResponse & {
      overlapDays: OverLaps[];
      recommendations: TypeUserData[];
    }
  > {
    return await ky
      .get(`${baseURL}${specialists.getRecommendedSpecialists}/${userId}`, {
        headers: getAuthHeader(token),
      })
      .then((res) => res.json());
  }

  static async getSpecialistIntroVideo(
    token: string,
    id: string
  ): Promise<TypeDefaultResponse> {
    return await ky
      .get(`${baseURL}${specialists.getSpecialistIntroVideo}/${id}`, {
        headers: getAuthHeader(token),
      })
      .then((res) => res.json());
  }

  static async getSpecialistCertificates(
    token: string,
    id: string,
    page: number,
    amount: number
  ): Promise<
    TypeDefaultResponse & {
      certificates?: Certificates[];
      total: number;
    }
  > {
    return await ky
      .get(
        `${baseURL}${specialists.acceptRealTimeSessionRequest}/${id}/${page}/${amount}`,
        {
          headers: getAuthHeader(token),
        }
      )
      .then((res) => res.json());
  }

  static async getSpecialistPublicCertificates(
    id: string,
    page: number,
    amount: number
  ): Promise<
    TypeDefaultResponse & {
      certificates?: Certificates[];
      total: number;
    }
  > {
    return await ky
      .get(
        `${baseURL}${specialists.acceptRealTimeSessionRequest}/${id}/${page}/${amount}`
      )
      .then((res) => res.json());
  }

  static async getMainSpecializations(token: string): Promise<
    TypeDefaultResponse & {
      mainSpecializations?: MainSpecializations[];
    }
  > {
    return await ky
      .get(`${baseURL}${specialists.getMainSpecializations}`, {
        headers: getAuthHeader(token),
      })
      .then((res) => res.json());
  }

  static async getSubSpecializations(
    token: string,
    relatedMainSpec: string[]
  ): Promise<
    TypeDefaultResponse & {
      subSpecializations?: SubSpecializations[];
    }
  > {
    return await ky
      .get(
        `${baseURL}${specialists.getSubSpecializations}/${relatedMainSpec}`,
        {
          headers: getAuthHeader(token),
        }
      )
      .then((res) => res.json());
  }

  static async getFilteredSpecialists(
    token: string,
    payload: {
      userId: string;
      filters: TypeSpecialistFilter;
      alreadyFetched?: string[];
    }
  ): Promise<
    TypeDefaultResponse & {
      paramsAvailable: ParamsAvailable;
      users?: TypeUserData[];
      overlapDays: OverLaps[];
    }
  > {
    return await ky
      .post(`${baseURL}${specialists.getFilteredSpecialists}`, {
        json: payload,
        headers: getAuthHeader(token),
      })
      .then((res) => res.json());
  }

  static async getFilterAvailableParams(
    token: string,
    payload: { filters: TypeSpecialistFilter }
  ): Promise<
    TypeDefaultResponse & {
      paramsAvailable: ParamsAvailable;
    }
  > {
    return await ky
      .post(`${baseURL}${specialists.getFilterAvailableParams}`, {
        json: payload,
        headers: getAuthHeader(token),
      })
      .then((res) => res.json());
  }

  static async getAvailableParams(
    token: string,
    payload: { param: string; filters: TypeSpecialistFilter }
  ): Promise<string[]> {
    return await ky
      .post(`${baseURL}getAvailableParams`, {
        json: payload,
        headers: getAuthHeader(token),
      })
      .then((res) => res.json());
  }

  static async removeCertificate(
    token: string,
    payload: {
      certificateId: string;
      specialistId: string;
    }
  ): Promise<TypeDefaultResponse> {
    return await ky
      .post(
        `${baseURL}${specialists.acceptRealTimeSessionRequest}/${payload.specialistId}`,
        {
          headers: getAuthHeader(token),
          json: payload,
        }
      )
      .then((res) => res.json());
  }

  static async getSpecialistFreeTime(
    token: string,
    specialistUserId: string,
    specialistId: string
  ): Promise<TypeDefaultResponse & { overlapDays: SpecialistFreeTimeType[] }> {
    return await ky
      .get(
        `${baseURL}${specialists.getSpecialistFreeTime}/${specialistUserId}/${specialistId}`,
        {
          headers: getAuthHeader(token),
        }
      )
      .then((res) => res.json());
  }

  static async sendRealTimeSessionRequest(
    token: string,
    payload: {
      specialistUserId: string;
      specialistId: string;
      userId: string;
      subject: string;
    }
  ): Promise<
    TypeDefaultResponse & {
      booking?: RealTimeBooking | null;
      userIdToNotify?: string;
      systemMessageData?: {
        userIds: string[];
        message: string;
      };
      notEnoughBalance: boolean;
    }
  > {
    return await ky
      .post(`${baseURL}${specialists.sendRealTimeSessionRequest}`, {
        headers: getAuthHeader(token),
        json: payload,
      })
      .then((res) => res.json());
  }

  static async acceptRealTimeSessionRequest(
    token: string,
    userId: string,
    bookingId: string
  ): Promise<
    TypeDefaultResponse & {
      session?: Sessions | null;
      userIdToNotify?: string;
      systemMessageData?: {
        userIds: string[];
        message: string;
      };
      googleEventsData?: {
        userId: string;
        eventInfo: TypeGoogleEvent;
      }[];
    }
  > {
    return await ky
      .post(`${baseURL}${specialists.acceptRealTimeSessionRequest}`, {
        headers: getAuthHeader(token),
        json: { userId, bookingId },
      })
      .then((res) => res.json());
  }

  static async cancelRealTimeSessionRequest(
    token: string,
    userId: string,
    bookingId: string,
    reason: string
  ): Promise<
    TypeDefaultResponse & {
      userIdToNotify?: string;
      systemMessageData?: {
        userIds: string[];
        message: string;
      };
    }
  > {
    return await ky
      .post(`${baseURL}${specialists.cancelRealTimeSessionRequest}`, {
        headers: getAuthHeader(token),
        json: { userId, bookingId, reason },
      })
      .then((res) => res.json());
  }

  static async getAllSpecialzations(token: string): Promise<
    TypeDefaultResponse & {
      specializations?: Specializations[];
    }
  > {
    return await ky
      .get(`${baseURL}${specialists.getAllSpecialzations}`, {
        headers: getAuthHeader(token),
      })
      .then((res) => res.json());
  }
  static async getSpecialistTags(
    token: string,
    payload?: {
      parentTagsIds?: string[];
      direction?: SpecialistTagsDirections;
      level?: number;
    }
  ): Promise<TypeDefaultResponse & { tags?: SpecialistTag[] }> {
    return await ky
      .post(`${baseURL}${specialists.getSpecialistTags}`, {
        headers: getAuthHeader(token),
        json: payload,
      })
      .then((res) => res.json());
  }

  static async addTagsToSpecialist(
    token: string,
    userId: string,
    tagIds: string[],
    specialistId: string
  ): Promise<TypeDefaultResponse & { tags?: SpecialistTag[] }> {
    return await ky
      .post(`${baseURL}${specialists.addTagsToSpecialist}/${userId}`, {
        headers: getAuthHeader(token),
        json: { userId, tagIds, specialistId },
      })
      .then((res) => res.json());
  }

  static async removeTagsFromSpecialist(
    token: string,
    userId: string,
    tagIds: string[],
    specialistId: string
  ): Promise<TypeDefaultResponse & { tags?: SpecialistTag[] }> {
    return await ky
      .post(`${baseURL}${specialists.removeTagsFromSpecialist}/${userId}`, {
        headers: getAuthHeader(token),
        json: { userId, tagIds, specialistId },
      })
      .then((res) => res.json());
  }

  static async addNewSpecialistProfileToUser(
    token: string,
    userId: string
  ): Promise<TypeDefaultResponse & { specialistId?: string }> {
    return await ky
      .post(
        `${baseURL}${specialists.addNewSpecialistProfileToUser}/${userId}`,
        {
          headers: getAuthHeader(token),
        }
      )
      .then((res) => res.json());
  }

  static async uploadEducationCertificates(
    specialistId: string,
    formData: FormData,
    token: string
  ): Promise<
    TypeDefaultResponse & { certificate: EducationCertificatesResponse }
  > {
    return await ky
      .post(
        `${baseURL}${specialists.uploadEducationCertificates}/${specialistId}`,
        {
          body: formData,
          headers: getAuthHeader(token),
          timeout: 300000,
        }
      )
      .then((res) => res.json());
  }
  static async uploadFederationCertificates(
    specialistId: string,
    formData: FormData,
    token: string
  ): Promise<
    TypeDefaultResponse & { certificate: FederationsCertificatesResponse }
  > {
    return await ky
      .post(
        `${baseURL}${specialists.uploadFederationCertificates}/${specialistId}`,
        {
          body: formData,
          headers: getAuthHeader(token),
          timeout: 300000,
        }
      )
      .then((res) => res.json());
  }
  static async uploadQualificationCertificates(
    specialistId: string,
    formData: FormData,
    token: string
  ): Promise<
    TypeDefaultResponse & { certificate: QualificationCertificatesResponse }
  > {
    return await ky
      .post(
        `${baseURL}${specialists.uploadQualificationCertificates}/${specialistId}`,
        {
          body: formData,
          headers: getAuthHeader(token),
          timeout: 300000,
        }
      )
      .then((res) => res.json());
  }

  static async removeEducationCertificate(
    token: string,
    certificateId: string,
    specialistId: string
  ): Promise<TypeDefaultResponse> {
    return await ky
      .post(
        `${baseURL}${specialists.removeEducationCertificate}/${specialistId}`,
        {
          json: { certificateId },
          headers: getAuthHeader(token),
          timeout: 300000,
        }
      )
      .then((res) => res.json());
  }

  static async removeQualificationCertificate(
    token: string,
    certificateId: string,
    specialistId: string
  ): Promise<TypeDefaultResponse> {
    return await ky
      .post(
        `${baseURL}${specialists.removeQualificationCertificate}/${specialistId}`,
        {
          json: { certificateId },
          headers: getAuthHeader(token),
          timeout: 300000,
        }
      )
      .then((res) => res.json());
  }

  static async removeFederationCertificate(
    token: string,
    certificateId: string,
    specialistId: string
  ): Promise<TypeDefaultResponse> {
    return await ky
      .post(
        `${baseURL}${specialists.removeFederationCertificate}/${specialistId}`,
        {
          json: { certificateId },
          headers: getAuthHeader(token),
          timeout: 300000,
        }
      )
      .then((res) => res.json());
  }

  static async getFederations(specialization: string): Promise<
    TypeDefaultResponse & {
      federations?: Federation[];
    }
  > {
    return await ky
      .get(`${baseURL}${specialists.getFederations}/${specialization}`, {})
      .then((res) => res.json());
  }

  static async getEducations(specialization: string): Promise<
    TypeDefaultResponse & {
      educations?: Education[];
    }
  > {
    return await ky
      .get(`${baseURL}${specialists.getEducations}/${specialization}`, {})
      .then((res) => res.json());
  }

  static async getQualifications(specialization: string): Promise<
    TypeDefaultResponse & {
      qualifications?: Qualification[];
    }
  > {
    return await ky
      .get(`${baseURL}${specialists.getQualifications}/${specialization}`, {})
      .then((res) => res.json());
  }

  static async createEducation(
    token: string,
    payload: {
      educationText: string;
      specialization: string;
    }
  ): Promise<TypeDefaultResponse & { education?: Education }> {
    return await ky
      .post(`${baseURL}${specialists.createEducation}`, {
        json: payload,
        headers: getAuthHeader(token),
        timeout: 300000,
      })
      .then((res) => res.json());
  }

  static async createFederation(
    token: string,
    payload: {
      federationText: string;
      specialization: string;
      badgeId: string;
    }
  ): Promise<TypeDefaultResponse & { federation?: Federation }> {
    return await ky
      .post(`${baseURL}${specialists.createFederation}`, {
        json: payload,
        headers: getAuthHeader(token),
        timeout: 300000,
      })
      .then((res) => res.json());
  }

  static async createQualification(
    token: string,
    payload: {
      qualificationText: string;
      specialization: string;
      badgeId: string;
    }
  ): Promise<TypeDefaultResponse & { qualification?: Qualification }> {
    return await ky
      .post(`${baseURL}${specialists.createQualification}`, {
        json: payload,
        headers: getAuthHeader(token),
        timeout: 300000,
      })
      .then((res) => res.json());
  }

  static async createFederationBadge(
    token: string,
    payload: FormData
  ): Promise<TypeDefaultResponse & { badge?: Badge }> {
    return await ky
      .post(`${baseURL}${specialists.createFederationBadge}`, {
        body: payload,
        headers: getAuthHeader(token),
        timeout: 300000,
      })
      .then((res) => res.json());
  }

  static async getEducationCertificates(
    token: string,
    specialistId: string,
    page: number,
    amount: number
  ): Promise<
    TypeDefaultResponse & {
      certificates?: EducationCertificatesResponse[];
      total?: number;
    }
  > {
    return await ky
      .get(
        `${baseURL}${specialists.getEducationCertificates}/${specialistId}/${page}/${amount}`,
        { headers: getAuthHeader(token) }
      )
      .then((res) => res.json());
  }

  static async getEducationPublicCertificates(
    specialistId: string,
    page: number,
    amount: number
  ): Promise<
    TypeDefaultResponse & {
      certificates?: EducationCertificatesResponse[];
      total?: number;
    }
  > {
    return await ky
      .get(
        `${baseURL}${specialists.getEducationPublicCertificates}/${specialistId}/${page}/${amount}`,
        {}
      )
      .then((res) => res.json());
  }

  static async getFederationCertificates(
    token: string,
    specialistId: string,
    page: number,
    amount: number
  ): Promise<
    TypeDefaultResponse & {
      certificates?: FederationsCertificatesResponse[];
      total?: number;
    }
  > {
    return await ky
      .get(
        `${baseURL}${specialists.getFederationCertificates}/${specialistId}/${page}/${amount}`,
        { headers: getAuthHeader(token) }
      )
      .then((res) => res.json());
  }

  static async getFederationPublicCertificates(
    specialistId: string,
    page: number,
    amount: number
  ): Promise<
    TypeDefaultResponse & {
      certificates?: FederationsCertificatesResponse[];
      total?: number;
    }
  > {
    return await ky
      .get(
        `${baseURL}${specialists.getFederationPublicCertificates}/${specialistId}/${page}/${amount}`,
        {}
      )
      .then((res) => res.json());
  }

  static async getQualificationCertificates(
    token: string,
    specialistId: string,
    page: number,
    amount: number
  ): Promise<
    TypeDefaultResponse & {
      certificates?: QualificationCertificatesResponse[];
      total?: number;
    }
  > {
    return await ky
      .get(
        `${baseURL}${specialists.getQualificationCertificates}/${specialistId}/${page}/${amount}`,
        { headers: getAuthHeader(token) }
      )
      .then((res) => res.json());
  }

  static async getQualificationPublicCertificates(
    specialistId: string,
    page: number,
    amount: number
  ): Promise<
    TypeDefaultResponse & {
      certificates?: QualificationCertificatesResponse[];
      total?: number;
    }
  > {
    return await ky
      .get(
        `${baseURL}${specialists.getQualificationPublicCertificates}/${specialistId}/${page}/${amount}`,
        {}
      )
      .then((res) => res.json());
  }

  static async getAllSpecialistBadges(
    token: string,
    specialistId: string
  ): Promise<
    TypeDefaultResponse & {
      badges: Badge[];
    }
  > {
    return await ky
      .get(`${baseURL}${specialists.getAllSpecialistBadges}/${specialistId}`, {
        headers: getAuthHeader(token),
      })
      .then((res) => res.json());
  }
  static async getAllSpecialistUserBadges(specialistUserId: string): Promise<
    TypeDefaultResponse & {
      badges: Badge[];
    }
  > {
    try {
      const res = await ky.get(
        `${baseURL}${specialists.getAllSpecialistUserBadges}/${specialistUserId}`
      );
      return await res.json();
    } catch (error) {
      console.error("Error fetching badges:", error);
      throw error;
    }
  }
}
